.thriteen {
    padding: 5rem 0;

    .innerSthirdth {
        max-width: 1200px;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .hededer {
        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 42px;
        text-align: center;
    }

    .line {
        height: 3px;
        border-radius: 30px;
        background: red;
        width: 300px;
        margin-bottom: 4rem;
        margin-top: 6px;
        text-align: center;
    }

    .text {
        color: #fff;
        text-align: left;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        margin-left: 10px;
    }

    .addone {
        display: flex;
        align-items: center;
        padding-bottom: 2rem;
        margin-top: 2rem;
        border-bottom: 1px solid #ddd;
        width: 100%;
        justify-content: space-between;

        svg {
            cursor: pointer;

            &:hover {
                transform: scale(1.02);

            }
        }
    }
}
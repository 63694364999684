.casestudy {
  width: 100%;
  height: 100vh;
  background: white;
  color: #15182B;
  position: relative;
  padding-top: 41px;

  .casestudy-navbar {
    width: 100%;
    position: sticky;

    .navbar {
      width: 100%;
      height: 5rem;
      background: #16171a;
      display: flex;
      align-items: center;
      padding: 0 10rem;
      justify-content: space-between;

      img {
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
          transform: scale(1.05);
        }
      }

      .hero-btn {
        // background: #59a2dd;
        background: linear-gradient(179.39deg, #F05C4D 10.56%, #B580F2 105.06%);
        padding: 1rem 3rem;
        width: fit-content;
        border-radius: 35px;
        color: white;
        font-weight: 600;
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
          transform: scale(1.05);
        }
      }
    }

    .navbar-line {
      width: 100%;
      height: 5px;
      // background: #59a2dd;
      background: linear-gradient(179.39deg, #F05C4D 10.56%, #B580F2 105.06%);
    }
  }

  .casestudy-linearBg {
    width: 100%;
    height: 10rem;
  }

  .casestudy-content {
    width: 100%;
    display: flex;
    height: calc(100% - 15rem - 5px);
    position: relative;

    .casestudy-icon-div {
      position: absolute;
      width: 100%;
      top: -2rem;
      padding-left: 10rem;
      z-index: 1;

      .casestudy-icon {
        width: 128px;
        border-radius: 15px;
        border: 0.5px solid #efefef;
        background: #fff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;
        aspect-ratio: 1/1;
        padding: 2rem;

        img {
          width: 100%;
        }
      }
    }

    .casestudy-menu {
      width: calc(10rem + 64px);
      height: 100%;
      border-right: 0.5px solid #efefef;
      padding: 10rem 0 7rem 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .each-cs-menu1 {
        font-weight: 600;
        color: #15182B;
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
          transform: scale(1.05);
        }
      }

      .each-cs-menu2 {
        color: rgba(22, 23, 26, 0.5);
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
          transform: scale(1.05);
        }
      }
    }

    .casestudy-right-content {
      .mobileContent {
        display: none;
      }

      width: calc(100% - 10rem - 64px);
      height: 100%;
      padding-right: 10rem;
      padding-left: 7rem;
      z-index: 2;

      .casestudy-name-div {
        width: 100%;
        height: 6rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .casestudy-name-txt {
          font-size: 2.5rem;
        }

        .web-Btn {
          background: white;
          color: #16171A;
          border: 1px solid #D2D2D2;
          border-radius: 35px;
          padding: 0.7rem 2.5rem;
          font-weight: 600;
          cursor: pointer;
          transition: all ease 0.2s;

          &:hover {
            transform: scale(1.05);
          }
        }
      }

      .menu-data {
        width: 100%;
        height: calc(100% - 6rem);
        padding: 4rem 0 2rem 0;
        overflow-y: scroll;

        .object-data {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 3rem;

          .obj-top-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .uline {
              font-size: 18px;
              font-weight: 400;
              text-decoration-line: underline;
            }

            .about-txt {
              width: 100%;
              font-weight: 300;
              line-height: 1.8;
            }
          }
        }

        .approach-data {
          width: 100%;
          height: 100%;
          display: flex;
          gap: 2rem;
          overflow-y: scroll;

          .each-approach-data {
            width: calc((100% - 4rem) / 3);
            height: fit-content;
            border-radius: 35px;
            border: 0.5px solid #e5e5e5;
            padding-bottom: 18px;
            display: flex;
            flex-direction: column;

            .approach-top-area {
              width: 100%;
              height: 18px;
              border-top-left-radius: 35px;
              border-top-right-radius: 35px;
              background: #59a2dd;
            }

            .approach-heading {
              font-size: 1.75rem;
              font-weight: 500;
              line-height: 1.3;
              padding: 2rem;
              display: flex;
              align-items: center;
            }

            .approach-subhead {
              // color: #59a2dd;
              font-weight: 600;
              padding: 0 2rem 1rem;
              line-height: 1.5;
            }

            .each-approach-text {
              font-weight: 300;
              line-height: 1.8;
              padding: 1rem 2rem;
              font-size: 0.85rem;
            }

            .each-approach-btn-div {
              width: 100%;
              padding: 2rem 2rem 0;

              .each-approach-btn {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #59a2dd;
                padding: 0.75rem 3rem;
                font-weight: 600;
                border-radius: 35px;
                color: white;
                cursor: pointer;
                transition: all ease 0.2s;

                &:hover {
                  transform: scale(1.05);
                }
              }
            }
          }
        }

        .req-data {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .uline {
            font-size: 18px;
            font-weight: 400;
            text-decoration-line: underline;
          }

          .mapping-data {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            margin-top: 0.5rem;
            align-items: start;

            .each-mapping-data {
              display: flex;
              align-items: center;
              gap: 10px;
              width: 100%;
              font-weight: 600;
            }

            .each-description {
              font-weight: 300;
              margin-left: 27px;
              line-height: 1.8;
            }
          }
        }

        .soln-data {
          width: 100%;
          height: 100%;
          display: flex;
          overflow-x: scroll;
          gap: 2rem;

          .each-testimonial {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 2rem;

            .quote-area {
              width: 100%;
              display: flex;
              color: #15182B;
              font-size: 18px;
              font-weight: 400;
              line-height: 1.8;
              position: relative;
              padding-left: 62px;
              padding-top: 0.75rem;

              .quote-img {
                position: absolute;
                inset: 0;
                top: -10px;
              }
            }

            .testimonial-data {
              width: 100%;
              border-radius: 35px;
              background: #f3f4f5;
              padding: 2rem 2rem 2rem 62px;
              display: flex;
              flex-direction: column;
              gap: 1rem;

              .testimonial-name {
                color: #59a2dd;
                font-weight: 600;
                font-size: 18px;
              }

              .testimonial-position {
                color: #15182B;
                font-weight: 600;
              }
            }
          }
        }

        .results-data {
          width: 100%;
          height: 100%;
          display: flex;

          .carousel-container {
            min-width: 100%;

            .react-multi-carousel-track {
              height: 100%;
            }

            .react-multi-carousel-dot-list {
              display: none;
            }

            .react-multiple-carousel__arrow {
              position: fixed;
            }

            .react-multiple-carousel__arrow--left {
              left: calc(12rem + 64px);
            }

            .react-multiple-carousel__arrow--right {
              right: 5rem;
            }

            .each-result-data {
              min-width: 100%;
              height: 99%;
              border-radius: 20px;
              border: 1px solid #e5e5e5;
              box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
              display: flex !important;
              padding: 2rem;

              .left-result-data {
                width: 50%;
                height: 100%;
                display: flex;
                flex-direction: column;
                gap: 2rem;
                justify-content: center;
                padding-left: 1rem;
                padding-right: 3rem;

                .left-heading {
                  font-size: 2.5rem;
                }

                .left-des {
                  font-weight: 300;
                  line-height: 1.8;
                }
              }

              .right-result-data {
                width: 50%;
                height: 100%;
                border-radius: 20px;
                border: 0.5px solid #e5e5e5;
                cursor: pointer;
                transition: all ease 0.2s;

                &:hover {
                  border: none;
                  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                }

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 20px;
                }
              }
            }
          }
        }
      }
    }
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    cursor: pointer;

    .modal-content {
      max-width: 85%;
      max-height: 85%;
      overflow: hidden;
      background-color: #fff;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);

      img {
        max-width: 100%;
        max-height: 100%;
        display: block;
        margin: 0 auto;
        padding: 10px;
      }
    }
  }
}

// media query 

@media only screen and (max-width: 800px) {
  .hero-btn {
    visibility: hidden;
    position: relative;
  }

  .hero-btn:after {
    visibility: visible;
    position: absolute;
    top: 0;
    right: 0;
    content: "Get Started";

    // background: #59a2dd;
    background: linear-gradient(179.39deg, #F05C4D 10.56%, #B580F2 105.06%);
    padding: 1rem 2rem;
    width: fit-content;
    border-radius: 35px;
    color: white;
    font-weight: 600;
    cursor: pointer;
    transition: all ease 0.2s;

    &:hover {
      transform: scale(1.05);
    }
  }

  .casestudy {
    overflow: scroll;
    position: relative;

    .casestudy-navbar {
      z-index: 999;
      position: sticky;
      top: 0;

      .navbar {
        padding: 0 2rem;

      }
    }

    .casestudy-content {

      .casestudy-menu {
        display: none;
      }

      .casestudy-icon-div {
        position: absolute;
        width: 100%;
        top: -4rem;
        padding-left: 2rem;
        z-index: 1;

        .casestudy-icon {
          width: 90px;
          border-radius: 15px;
          border: 0.5px solid #efefef;
          background: #fff;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          display: flex;
          justify-content: center;
          align-items: center;
          aspect-ratio: 1/1;
          padding: 2rem;
        }
      }

      .casestudy-right-content {
        padding-right: 5rem;
        padding-left: 2rem;
        width: 100%;

        .mobileContent {
          display: block;
          padding-top: 5rem;


          .sectionContainer {

            // height: 100vh;
            .sectionTitle {
              color: white;
              font-size: 2rem;
              margin-top: -3.3rem;
              font-weight: 500;
            }

            .sectionTextStyle {
              font-size: 1.2rem;
              font-weight: 300;
              line-height: 2.3rem;
              padding: 3.75rem 0rem;

              .sectionCard {
                border-radius: 20px;
                border: 1px solid #E5E5E5;
                background: #FFF;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                width: 85vw;
                padding: 2rem;

                .left-heading {
                  font-size: 2rem;
                  padding-bottom: 18px;
                  font-weight: 500;
                }
              }
            }

            .each-approach-data {
              .approach-heading {
                font-size: 2rem;
              }

              .approach-subhead {
                font-size: 18px;
                padding-top: 1rem;
              }

              .each-approach-text {
                padding-top: 2.3rem;
                font-size: 1.2rem;
                line-height: 2.3rem;
                padding-bottom: 2.8rem;
              }

            }
          }
        }

        .menu-data {
          display: none;
        }

        .casestudy-name-div {
          .casestudy-name-txt {
            font-size: 2rem;
            padding-top: 10rem;
          }
        }
      }

      .web-Btn {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: -1.3rem;
        margin-right: 2rem;
      }
    }
  }
}
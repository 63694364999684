.chatMain {
    background: transparent;

    .formbold-mb-5 {
        margin-bottom: 20px;
    }

    .chattopsection {
        display: flex;
        align-items: start;
        gap: 1rem
    }

    .formbold-pt-3 {
        padding-top: 12px;
    }

    .formbold-main-wrapper {}

    .formbold-form-wrapper {
        transition: all 0.5s ease;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        position: fixed;
        z-index: 9;
        background: white;
        width: 25rem;
        bottom: 114px;
        right: 22px;
        height: 30rem;
        border-radius: 15px;
        height: 26.2rem;
        transition: transform 500ms linear;
        // display: none;
    }

    .formbold-form-wrapper.active {
        display: block;
    }

    .formbold-form-label {
        display: block;
        font-weight: 700;
        font-size: 12px;
        color: #07074d;
        margin-bottom: 3px;
    }

    .formbold-form-label-2 {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 20px;
    }

    .formbold-form-input {
        width: 100%;
        padding: 10px 10px;
        border-radius: 6px;
        border: 1px solid #e0e0e0;
        background: white;
        font-weight: 500;
        font-size: 14px;
        color: #000;
        outline: none;
        resize: none;
    }

    .formbold-form-input:focus {
        // border-color: #6a64f1;
        // box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
    }

    .formbold-btn {
        text-align: center;
        font-size: 16px;
        border-radius: 6rem;
        padding: 10px 33px;
        border: none;
        font-weight: 600;

        color: black;
        cursor: pointer;
        font-weight: 700;
        cursor: pointer;
        background: #FBD10D;
        transition: all 0.5s ease;
    }



    .formbold-btn:hover {
        transition: all 0.5s ease;
        transform: scale(1.03);
    }

    .formbold--mx-3 {
        margin-left: -12px;
        margin-right: -12px;
    }

    .formbold-px-3 {
        padding-left: 12px;
        padding-right: 12px;
    }

    .flex {
        display: flex;
    }

    .flex-wrap {
        flex-wrap: wrap;
    }

    .w-full {
        width: 100%;
    }

    .formbold-form-header {
        background: #FBD10D;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 21px 16px;
        border-radius: 15px 15px 0px 0px;
    }

    button {

        svg {
            cursor: pointer;
            transition: all 0.5s ease;

            &:hover {
                transform: rotate(360deg);
                transition: all 0.5s ease;
            }
        }

        svg {
            fill: black !important;
        }
    }

    button:focus {
        outline: 0px auto -webkit-focus-ring-color !important;
    }

    .formbold-form-header h3 {
        font-weight: 700;
        font-size: 18px;
        color: black;
    }

    .formbold-form-header button {
        color: white;
        background: transparent;
        border: none;
    }

    .formbold-chatbox-form {
        padding: 16px 16px;
    }

    .formbold-action-buttons {
        max-width: 550px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 48px;
        position: fixed;
        z-index: 99;
        bottom: 33px;
        right: 19px;
    }

    .formbold-action-btn {
        width: 70px;
        height: 70px;
        background: #FBD10D;
        color: white;
        border-radius: 50%;
        margin-left: 20px;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.5s ease;
        // background: linear-gradient(118deg, #fdcd3b 41%, #fdfdfd 60%);

        svg {
            fill: #000 !important
        }

        &:hover {
            transition: all 0.5s ease;
            transform: rotate(360deg);
        }
    }

    .formbold-action-btn .formbold-cross-icon {
        display: none;
    }

    .formbold-action-btn.active .formbold-cross-icon {
        display: block;
    }

    .formbold-action-btn.active .formbold-chat-icon {
        display: none;
    }
}

@media (min-width: 540px) {
    .sm\:w-half {
        width: 50%;
    }
}
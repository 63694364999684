.ingse {
    height: 80%;
}

.mobileinner {
    display: none;
}

.delivarysection {
    margin-top: 1.5rem;
    display: flex;
    align-items: self-start;
    flex-wrap: wrap;
    gap: 2%;
    row-gap: 1.3rem;

    .delivarysectionCard {
        width: 32%;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
        }

        .Nameofcards {
            margin-top: 10px;
            font-family: Montserrat;
            font-size: 15px;
            font-weight: 400;
            line-height: 20px;
            text-align: center;
            color: #000;
            text-transform: capitalize;
        }

        .toplist {
            width: 100%;
            border: 1px solid #E5E5E5;
            font-family: Montserrat;
            font-size: 30px;
            font-weight: 500;
            line-height: 20px;
            text-align: center;
            color: #000;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 179px;

            border-radius: 10px;

        }
    }
}

.ai-startup {
    background: #FBD10D;

    img {
        position: absolute;
        right: 0;
        top: -72px;
    }

    .inner {
        margin: auto;
        max-width: 1200px;
        position: relative;
        margin: auto;
        max-width: 1200px;
        position: relative;
        padding: 91px 0rem;
    }

    .apply {
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 700;
        line-height: 25px;
        text-align: center;
        color: #FFFFFF;
        width: 328px;
        height: 60.3px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background: #000000;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
        }
    }

    .para {
        margin-top: 2rem;
        margin-bottom: 3rem;
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 600;
        line-height: 35px;
        text-align: left;
        width: 635px;


    }

    .text {
        font-family: Montserrat;
        font-size: 55px;
        font-weight: 400;
        line-height: 65px;
        text-align: left;
        color: #000000;

        span {
            font-weight: 700;
        }
    }


}

.affliliate {
    display: flex;
    align-items: center;

    .rigsidecontent {
        display: flex;
        align-items: start;
        gap: 1rem;

    }

    .rigistmain {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 2rem;
        position: relative;
        overflow: hidden;
        margin-top: 3rem;
    }

    .boxline {
        width: 28px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .affliheader {
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 600;
        line-height: 40px;
        text-align: left;
        color: #000;
        margin-bottom: 1rem;
    }

    .afflipara {
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 300;
        line-height: 25px;
        text-align: left;
        color: #000;
    }

    .box {
        min-width: 28px;
        min-height: 28px;

        border-radius: 5px;

        background: #FBD10D;

    }

    .line {
        width: 2px;
        height: 100%;
        background: #000;
        position: absolute;
        z-index: -1;
    }
}

.lanuchSection {


    .topbox {
        border: 1px solid #E5E5E5;
        border-radius: 15px;
        box-shadow: 0px 4px 4px 0px #00000040;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 2.5rem;
        padding-right: 2rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .cominngsoon {
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 500;
        line-height: 25px;
        text-align: center;
        width: 200px;
        height: 48.76px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 10px;
        border: 1px solid #E5E5E5;
        margin-top: 2rem;

        &:hover {
            transform: scale(1.05);
        }
    }
}

.threidsection {
    .recutImagesection {
        display: flex;
        align-items: start;
        gap: 4rem;
    }

    .sectionlist {
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 700;
        line-height: 25px;
        text-align: center;
        color: #FFFFFF;
        padding: 0.6rem 1.3rem;
        border-radius: 10px;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
        }
    }

    .text-recut {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 300;
        line-height: 28px;
        text-align: left;
        color: #000;
    }

    .customtextcard {
        display: flex;
        align-items: self-start;
        justify-content: space-between;
        margin-top: 2.2rem;
        margin-bottom: 2.2rem;
    }

    .recutext {
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 300;
        line-height: 23px;
        text-align: left;
        color: #000;
        margin-top: 1.3rem;


    }

    .recutcard {
        box-shadow: 0px 4px 4px 0px #00000040;
        border: 1px solid #E5E5E5;
        width: 48%;
        border-radius: 15px;
        padding: 1.5rem;

    }

    .cutomtop {
        display: flex;
        align-items: center;
        background: black;
        gap: 1rem;
        padding: 5px;
        border-radius: 15px;
        width: 100%;
        margin: auto;
    }
}

.selectedshowsection {
    padding-top: 10rem;
}

.accedemy-section-main {
    // padding: 2rem 0;
    padding-top: 4rem;

    .accndtheader {
        color: #000;
        font-family: Montserrat;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 3rem;
    }
}

.accedemy-section {
    display: flex;
    align-items: self-start;
    justify-content: space-between;
    width: 100%;

    .price {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 700;
        line-height: 25px;
        text-align: center;
        color: #000000;
        width: 377px;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background: #FBD10D;
        margin-top: 2rem;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
        }

    }

    .left {
        width: 47%;

        img {
            width: 100%;
            padding: 1rem;
            border-image: radial-gradient(rgba(0, 0, 0, 0) 89%, #FBD10D 0) 0.01% / 10px;
        }
    }

    .rightside {
        width: 47%;
    }

    .sectopnseleted {
        margin-top: 1.8rem;
        margin-bottom: 1rem;
    }

    .para {
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 300;
        line-height: 26px;
        text-align: left;
        color: #000000;

    }

    .list {
        border-bottom: 2px solid #E5E5E5;
        padding: 2rem 0;


    }

    .carlistacccmendy {
        display: flex;
        flex-direction: column;
        row-gap: 3rem;
        align-items: start;
        gap: 3rem;

        width: 100%;

        padding: 0.5rem 0;
    }

    label {
        margin-top: 1rem;
        width: 90%;
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        gap: 0px;
        border-radius: 10px;

        font-family: Montserrat;
        font-size: 13px;
        font-weight: 700;
        line-height: 15.85px;
        text-align: center;
        color: #fff;
        transition: all 0.5s ease;

        &:hover {
            transition: all 0.5s ease;
            transform: scale(1.03);
        }
    }

    .jointext {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 700;
        line-height: 23px;
        text-align: left;
        color: #000000;
        margin-bottom: 2rem;

    }

    .workshopcard {
        display: flex;
        align-items: start;
        padding: 1rem;
        padding-bottom: 0px;
        box-shadow: 0px 4px 4px 0px #00000040;
        background: #E5E5E54D;
        width: 100%;
        border-radius: 15px;
        cursor: pointer;
        transition: all 0.5s ease;

        &:hover {
            transition: all 0.5s ease;
            transform: scaleY(1.03);
        }
    }

    .regtext {
        font-family: Montserrat;
        font-size: 11px;
        font-weight: 400;
        line-height: 13.41px;
        text-align: center;
        background: #FFFFFF;
        border: 1px solid #E5E5E5;
        color: #000000;
        width: 181px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-top: 0.5rem;
        border-radius: 10px;
        transition: all 0.5s ease;

        &:hover {
            transition: all 0.5s ease;
            transform: scale(1.03);
        }

    }

    .topcardlist {
        min-width: 372px;
        min-height: 234px;
        padding: 1rem;
        border-radius: 15px;
        border: 0.5px;
        background: #FFFFFF;
        border: 0.5px solid #E5E5E5;
        box-shadow: 0px 4px 4px 0px #00000040;
        cursor: pointer;

        // svg {
        //     width: 7rem;

        // }
        transition: all 0.5s ease;

        &:hover {
            transition: all 0.5s ease;
            transform: scaleY(1.03);
        }
    }

    h5 {
        color: #000000;
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 300;
        line-height: 20px;
        text-align: left;
        margin-top: 1rem;

    }

    h4 {
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 500;
        line-height: 21.94px;
        text-align: left;
        color: #000000;
        margin-top: 1rem;

    }

    .comingsoon {
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 700;
        line-height: 15px;
        text-align: center;
        color: #000000;
        width: 106px;
        height: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        background: #FBD10D;

    }

    .text {
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 500;
        line-height: 30.48px;
        text-align: left;
        color: #000000;
        cursor: pointer;
        transition: all 0.5s ease;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:hover {
            transition: all 0.5s ease;
            transform: scale(1.03);
        }
    }
}

.loaderrlanding {
    height: 100vh;
    background: black;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 20rem;
        animation: bounce .4s ease infinite alternate;
        -webkit-animation: bounce .4s ease infinite alternate;
    }

}

@keyframes bounce {
    0% {
        text-shadow:
            0 5px 0 #ccc,
            0 2px 3px rgba(0, 0, 0, 1);
    }

    100% {
        transform: translateY(-20px);
        text-shadow: 0 50px 0 #000,
            0 0px 20px rgba(0, 0, 0, .8);
    }
}

.blacklinek {
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 30px;
    z-index: 2;
    background: #00000075;
}

video {
    width: 100%;
    height: 100% !important;
    position: absolute;
    object-fit: cover;
    z-index: 0;
    left: 0;
    bottom: 0;
    border-radius: 30px;
    top: 0
}

.protosectiondiv {
    display: flex;
    align-items: start;
    justify-content: space-between;
    margin-top: 4rem;
    width: 100%;

    .cardMianprototyping {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 2rem;
    }

    .onndecard {
        border-radius: 0 0 25px 25px;
        border: 1px solid #E5E5E5;
        background: #FFF;
        padding: 1rem;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    }

    .amounttext {
        font-family: Montserrat;
        font-size: 28px;
        font-weight: 600;
        line-height: 30px;
        text-align: center;
        color: #000000;
        margin-top: 30px;
        margin-bottom: 28px;
    }

    .list {
        display: flex;
        align-items: self-start;
        gap: 5px;
        margin-top: 10px;

        svg {
            min-width: 15px;
            min-height: 15px;
            margin-top: 4px;
        }
    }

    .textFounder {
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 400;
        line-height: 23px;
        text-align: center;
        color: #000000;

    }

    .labeltext {
        height: 50px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 700;
        line-height: 28px;
        color: #000000;
        background: #FBD10D;
        border-radius: 2rem;
        margin-top: 1.5rem;
        width: 100%;

        &:hover {
            transform: scale(1.03);
        }
    }

    .wiretext {
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        color: #000000;
    }

    .protheader {
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 600;
        line-height: 30px;
        text-align: center;
        background: #000000;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 54px;

        border-radius: 20px 20px 0px 0px;

        background: #FBD10D;

    }

    .rigitside {
        width: 48%;


        svg {
            fill: #000 !important;
        }
    }

    .leftside {
        width: 48%;
        display: flex;
        flex-direction: column;
        align-items: self-start;
        gap: 1rem;
    }

    .cardPrototying {
        background: #E5E5E54D;
        border-radius: 15px;
        padding: 1.5rem;
        width: 100%;
    }

    .textforcardoara {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 300;
        line-height: 22px;
        text-align: left;
        color: #000000;
        margin-top: 0px;

    }


    .listofcard {
        border: 1px solid #E5E5E5;
        border-radius: 10px;

        margin-top: 1.25rem;
        cursor: pointer;
        transition: transform 500ms linear;

        &:hover {
            transition: transform 500ms linear;
            transform: scale(1.03);
        }

        .tablelistbody {
            border-top: 1px solid #E5E5E5;
            display: flex;

            .tableheaderbody {
                font-family: Montserrat;
                font-size: 13px;
                font-weight: 500;
                line-height: 15.85px;
                text-align: left;

                color: #000000;
                width: 25%;
                border-right: 1px solid #E5E5E5;
                padding: 0.7rem 1rem;

                li {
                    margin-top: 1rem;
                }

                &:nth-child(1) {
                    width: 20%;
                }

                &:nth-child(3) {
                    width: 20%;
                }

                &:nth-last-child(1) {
                    width: 35%;
                    border-right: 0px solid #E5E5E5;
                }
            }
        }

        .tablelist {
            border-top: 1px solid #E5E5E5;
            display: flex;

            .tableheader {
                font-family: Montserrat;
                font-size: 13px;
                font-weight: 700;
                line-height: 15.85px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #000000;
                width: 25%;
                border-right: 1px solid #E5E5E5;
                padding: 0.6rem 1rem;

                &:nth-child(1) {
                    width: 20%;
                }

                &:nth-child(3) {
                    width: 20%;
                }

                &:nth-last-child(1) {
                    width: 35%;
                    border-right: 0px solid #E5E5E5;
                }

            }
        }

        .sublistofcard {
            padding: 1rem 1rem;
            background: #E5E5E5;
            border-radius: 10px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

        }

        .cutomdata {
            width: 100%;
            overflow: scroll;
        }

        .weekbio {
            padding: 1rem 1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            transition: transform 500ms linear;

            svg {
                transition: transform 500ms linear;
                // transform: rotate(180deg);
            }
        }

        .boldheader {
            font-family: Montserrat;
            font-size: 15px;
            font-weight: 700;
            line-height: 18.29px;
            text-align: left;
            color: #000000;

        }
    }





    .listItems {
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 500;
        line-height: 18.29px;
        text-align: left;
        color: #000000;
        cursor: pointer;
        padding: 0.4rem 1.3rem;
        border-radius: 8rem;
        transition: transform 500ms linear;

        &:hover {
            transform: scale(1.03);
            transition: transform 500ms linear;
        }
    }

    .textForcard {
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 600;
        line-height: 40px;
        text-align: left;
        color: #000000;
    }

    .NameSection {
        font-family: Montserrat;
        font-size: 28px;
        font-weight: 600;
        line-height: 40px;
        text-align: left;
        color: #000000;
        margin-bottom: 1.5rem;

    }

    .headerlist {
        display: flex;
        align-items: self-start;
        gap: 1.2rem;
    }

    .NameSectionsub {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 300;
        line-height: 28px;
        text-align: left;
        color: #000000;

    }

    .left {
        width: 47%
    }

    .right {
        width: 47%;
        padding: 1rem;
        border-image: radial-gradient(#0000 89%, #FBD10D 0) .01% / 10px;
    }
}

.prototypingsection {
    background: #333333;
    padding: 1.4rem;
    position: relative;
    border-radius: 25px;
    width: 100%;

    .flex-bottom-sec {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    svg {
        margin-bottom: 4rem;
        margin-top: 5rem;
        margin: 4rem auto;
        animation-name: rotation;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-duration: 10s;
        transform: rotateY(0deg);
        transform-style: preserve-3d;
    }

    @keyframes rotation {
        0% {
            transform: rotate3d(0, 1, 0, 0deg);
        }

        50% {
            transform: rotate3d(0, 1, 0, 180deg);
        }

        100% {
            transform: rotate3d(0, 1, 0, 360deg);
        }
    }

    .getStarted {
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 700;
        line-height: 25px;
        text-align: center;
        color: #fff;
        width: 200px;
        height: 48.76px;
        display: flex;
        align-items: center;
        justify-content: center;



        background: transparent;

        position: relative;
        overflow: hidden;
        cursor: pointer;
        transition: 0.5s;


        &:nth-child(1) {
            //   filter: hue-rotate(270deg);
        }

        &:nth-child(2) {
            //  filter: hue-rotate(110deg);
        }

        span {
            position: absolute;
            display: block;
            border-radius: 10px;

            &:nth-child(1) {
                top: 0;
                left: 0;
                width: 100%;
                height: 4px;
                background: linear-gradient(90deg, transparent, #FBD10D);
                animation: animate1 1s linear infinite;
            }
        }

        @keyframes animate1 {
            0% {
                left: -100%;
            }

            50%,
            100% {
                left: 100%;
            }
        }

        span:nth-child(2) {
            top: -100%;
            right: 0;
            width: 4px;
            height: 100%;
            background: linear-gradient(180deg, transparent, #FBD10D);
            animation: animate2 1s linear infinite;
            animation-delay: 0.50s;
        }

        @keyframes animate2 {
            0% {
                top: -100%;
            }

            50%,
            100% {
                top: 100%;
            }
        }

        span:nth-child(3) {
            bottom: 0;
            right: 0;
            width: 100%;
            height: 4px;
            background: linear-gradient(270deg, transparent, #FBD10D);
            animation: animate3 1s linear infinite;
            animation-delay: 0.5s;
        }

        @keyframes animate3 {
            0% {
                right: -100%;
            }

            50%,
            100% {
                right: 100%;
            }
        }

        span:nth-child(4) {
            bottom: -100%;
            left: 0;
            width: 4px;
            height: 100%;
            background: linear-gradient(360deg, transparent, #FBD10D);
            animation: animate4 1s linear infinite;
            animation-delay: 0.75s;
        }

        @keyframes animate4 {
            0% {
                bottom: -100%;
            }

            50%,
            100% {
                bottom: 100%;
            }
        }

        &:hover {
            transform: scale(1.04);
            border-radius: 32px;
            background: #FBD10D !important;
            color: #ffff;
            box-shadow: 0 0 5px #FBD10D, 0 0 25px #FBD10D, 0 0 50px #FBD10D,
                0 0 200px #FBD10D;
            -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
        }
    }

    .dev-text {
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 400;
        line-height: 24.38px;
        text-align: left;
        color: #fff;
    }

    .idea {
        font-family: Montserrat;
        font-size: 25px;
        font-weight: 700;
        line-height: 30.48px;
        text-align: left;
        color: #fff;
    }

    .weeks {
        width: 104px;
        height: 31.81px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;

        font-family: Montserrat;
        font-size: 13px;
        font-weight: 700;
        line-height: 15.85px;
        text-align: center;
        background: #FFFFFF;
        cursor: pointer;
        position: absolute;
        right: 1.4rem;

        top: 1.4rem;

        &:hover {
            transform: scale(1.04);
        }
    }
}

.landingsecondpage {
    background: black;
    padding: 100px 0;
margin-top: 60px;


    .wrapper {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        /* justify-content: center; */
        /* justify-content: space-between; */
        align-items: center;
        /* gap: 2em; */
        margin-block: 4em;
        margin-top: 6rem;
        transition: all 0.5s ease;
        row-gap: 6rem;
        gap: 2.6%;
        row-gap: 6rem;
    }

    .cardss {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 23%;
        height: 14em;
        padding: 1em;
        background: #fff;
        transition: all 0.5s ease;
        border-radius: 1em;
    }

    .cardss:hover {
        height: 17em;
        transition: all 0.5s ease;
    }

    .image {
        position: absolute;
        inset: 0.5em 0.5em 0em 0.5em;
        transition: all 0.5s ease;
    }

    .cardss:hover .image {
        position: absolute;
        inset: -4em 2em 0em 2em;
        transition: all 0.5s ease;
    }

    .img {
        max-width: 100%;
        height: 75%;
        border: 0em solid #0000;
        border-radius: 0.8em;
        transition: all 0.5s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

        img {
            height: 2.5rem;
        }
    }

    .cardss:hover .img {
        // border: 0.5em solid #171412;
        border-radius: 1.2em;
        height: 40%;
        transition: all 0.5s ease;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    p {
        position: absolute;
        top: 3em;
        opacity: 0;
        transition: all 0.5s ease;
        color: black;
        position: absolute;
        top: 82px;
        opacity: 1;
        transition: all 0.5s ease;
        font-size: 13px;
        line-height: 18px;
        display: -webkit-box;

        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .cardss:hover p {
        transition-delay: 0.15s;
        position: absolute;
        top: 6em;
        opacity: 1;
        transition: all 0.5s ease;
        display: -webkit-box;

        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
    }

    .button {
        position: absolute;
        bottom: 0.4em;
        left: 50%;
        translate: -50% 0;
        font-size: 16px;
        font-weight: 600;
        line-height: 30px;
        text-align: center;
        color: #000;
        margin-top: 5px;
        // background: #ef7926;
        height: 40px;
        border-radius: 4em;
        transition: all 0.5s ease;
        width: 83%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        cursor: pointer;

    }

    .cardss:hover .button {
        transition: all 0.5s ease;
        bottom: 0.6em;

        box-shadow: 0px 2px 2px 0px #FBD10D;

        background: #FBD10D;
        color: black;
    }


    .topcardsecond {
        width: 23%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;


        div {
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 600;
            line-height: 30px;
            text-align: center;
            color: white;
            margin-top: 5px;
        }
    }

    .inneropage {
        max-width: 1200px;

        margin: auto;
    }

    .cardcollect {
        display: flex;
        align-items: self-start;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 4.6rem;
        row-gap: 3rem;
    }

    .secondcard {
        width: 100%;
        height: 180px;

        border-radius: 10px;
        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            transform: scale(1.03);
        }
    }

    .textExplore {
        font-family: Montserrat;
        font-size: 30px;
        font-weight: 800;
        line-height: 40px;
        text-align: center;
        color: white;

        span {
            color: #FBD10D;

        }
    }
}

.landingpage {
    height: auto;
    padding-top: 173px;
    padding-left: 0;
    padding-bottom: 0rem;
    padding-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    .svgimage {
        display: none;
    }

    .inneropage {
        max-width: 1200px;
        margin: auto;
        width: 100%;
    }

    .headertop {
        font-family: Montserrat;
        font-size: 22px;
        font-weight: 700;
        line-height: 40px;
        position: relative;
        z-index: 3;
        text-align: center;
        color: #fbd10d;

    }

    .inner {
        width: 100%;
    }

    .mobileshow {
        position: relative;
        z-index: 22;
        width: 100%;
        display: none;
        margin-top: 2rem;
    }

    .carousel-button-group {
        position: relative;
        z-index: 3;

        div {
            color: white;
        }
    }

    .linemain {
        width: 865px;
        display: flex;
        align-items: self-start;
        justify-content: space-between;
        position: relative;
        z-index: 2;
        margin: auto;
        margin-top: 5rem;
    }

    .stringline {
        height: 3px;
        background-color: white;
        width: 100%;
        position: absolute;
        z-index: 2;
        top: 0;
    }

    .linestringabsole {
        height: 4rem;
        width: 3px;
        position: absolute;
        z-index: 2;
        background-color: white;
        left: 0;
        right: 0;
        margin: auto;
        top: -2rem;

    }

    .linestring {
        height: 2rem;
        width: 3px;
        position: relative;
        z-index: 2;
        background-color: white;
    }

    .paragragph {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 500;
        line-height: 25px;
        text-align: center;
        color: #fff;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }

    .listimte {
        display: flex;
        align-items: self-start;
        gap: 1rem;
        justify-content: space-between;
        /* flex-wrap: wrap; */
        margin-top: 4rem;
        /* width: 100%; */
        max-width: 1200px;
        margin: auto;

    }

    .headerdesk {
        font-family: Montserrat;
        font-size: 16px;

        line-height: 25px;
        text-align: center;
        color: #000000;
        width: 200px;
        height: 44px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FBD10D;
        font-weight: 700 !important;
        color: #000000 !important;
        cursor: pointer;
        transition: all 0.5s ease;

        &:hover {
            transform: scale(1.03);
            transition: all 0.5s ease;
        }

    }

    .listcards {
        width: 393px;
        display: flex;
        flex-direction: column;
        align-items: self-start;
        row-gap: 2.4rem;
    }

    .headertoptex {
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 700;
        line-height: 25px;
        text-align: center;
        color: #fff;
        margin: auto;

        z-index: 2;
        position: relative;
    }

    .cardselectiona {
        position: relative;
        z-index: 1;
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 250px;
        background: #00000085;
        padding: 25px;
        border-radius: 21px;
        transition: all .50s ease-in-out;
        -moz-transition: all .50s ease-in-out;
        -webkit-transition: all .50s ease-in-out;

        .backside {
            opacity: 0;
            position: absolute;
            left: 0;
            top: 0;
            cursor: pointer;
            border-radius: 15px;
            display: flex;
            align-items: c;
            align-items: center;
            justify-content: center;
            transition: all .50s ease-in-out;
            -moz-transition: all .50s ease-in-out;
            -webkit-transition: all .50s ease-in-out;
            flex-direction: column;
            padding: 10px;
            border: 1px solid #E5E5E5;
            box-shadow: 0px 4px 4px 0px #00000040;


        }

        .fornside {
            cursor: pointer;
            width: 100%;
            height: 100%;
            border-radius: 21px;
            position: absolute;
            top: 0;
            display: flex;
            align-items: c;
            transition: all .50s ease-in-out;
            -moz-transition: all .50s ease-in-out;
            -webkit-transition: all .50s ease-in-out;
            align-items: center;
            justify-content: center;
            opacity: 1;
        }

        &:hover {
            .backside {
                opacity: 1;
                transition: all .50s ease-in-out;
                -moz-transition: all .50s ease-in-out;
                -webkit-transition: all .50s ease-in-out;
            }

            .fornside {
                opacity: 0;
                transition: all .50s ease-in-out;
                -moz-transition: all .50s ease-in-out;
                -webkit-transition: all .50s ease-in-out;
            }

        }

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
        }
    }

    .headerdesk {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 400;
        line-height: 32px;
        text-align: center;
        color: #fff;
    }

    h1 {
        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 45px;
        font-style: normal;
        font-weight: 700;
        line-height: 52px;
        position: relative;
        z-index: 3;


        div {
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: 42px;
            margin-top: 10px;
        }

        /* 130.769% */
        span {
            color: #FBD10D;
        }
    }

    h5 {
        color: #000;
        text-align: center;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0;
        margin-top: 1rem;
    }

    .rotatdeteduv {
        width: 580px;
        height: 230px;

        border-radius: 15px;

        background: #D9D9D9;
        margin: 3rem auto;
    }

    label {
        width: 323.538px;
        height: 52.877px;
        flex-shrink: 0;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px;
        background: #FBD10D;
        box-shadow: 0 0 0 0 #FBD10D;
        animation: glow 1.4s ease-out infinite;
        cursor: pointer;
        transition: all 0.5s ease;
        position: relative;
        z-index: 3;
        margin-top: 3rem;
        background: #FBD10D;

        &:hover {
            transition: all 0.5s ease;
            transform: scale(1.04);
        }
    }

    @keyframes glow {
        0% {
            box-shadow: 0 0 0 0 #FBD10D;
        }

        50% {
            box-shadow: 0 0 30px 0 #FBD10D;
        }
    }

    h2 {
        color: #000;
        text-align: center;
        font-family: Montserrat;
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 3.5rem;
        margin-bottom: 3.5rem;
    }
}

.tabLayot {
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding-top: 8rem;

    .leftmaindrawer {
        width: 300px;
        // display: flex;
        // align-items: center;
    }

    .rightsidedrawer {
        width: calc(100% - 350px);
        padding-top: 3px;
    }

    .leftDrawer {
        border-radius: 25px;
        border: 1px solid #E5E5E5;
        background: #FFF;
        width: 100%;
        position: sticky;
        padding: 1.5rem;
        padding-left: 0;
        top: 0;


        img {
            margin-bottom: 2rem;
            margin-left: 1.5rem;

            &:hover {
                transform: scale(1.03);
            }
        }
    }

    .is-active {
        width: 100%;

        font-weight: 700;
        border-radius: 0px 35px 35px 0px;

        background: #FBD10D;

        .subtextlist {
            font-weight: 700;
        }

        a {
            font-weight: 700;
        }
    }

    .buttonLabel {
        padding-left: 1.5rem;
    }

    .labelmodify {
        position: relative;
        width: 100%;
    }

    .lableleftside {
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        line-height: 18.29px;
        text-align: center;
        border: 1px solid #E5E5E5;
        background: #FFFFFF;
        color: #000;
        position: absolute;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px 15px 0 0px;
        width: 8.5rem;
        transform: rotate(-90deg);
        /* width: 4rem; */
        height: 2.5rem;
        left: -88px;
        top: 40px;
    }

    .listtext {
        color: #000;
        font-family: Montserrat;

        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        cursor: pointer;
        line-height: normal;
        line-height: normal;
        width: 100%;
        height: 50px;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        margin-bottom: 1rem;
    }

    .subtextlist {
        padding-left: 1.5rem;
        font-family: Montserrat;
        font-size: 12px;
        font-style: italic;
        font-weight: 400;
        line-height: 14.63px;
        text-align: left;
        color: #000000;
        margin-top: 5px;
    }

    a {
        color: #000;
        font-family: Montserrat;
        padding-left: 1.5rem;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        cursor: pointer;
        line-height: normal;
        padding-top: 0px;

        &:hover {
            transform: scale(1.03);
        }
    }

    .title {
        color: #000;
        font-family: Montserrat;
        margin-bottom: 2rem;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        cursor: pointer;
        line-height: normal;

        &:hover {
            transform: scale(1.03);
        }
    }

    .label1 {
        color: #000;
        text-align: center;
        font-family: Montserrat;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40.238px;
        border-radius: 10px;
        background: #FBD10D;
        margin-bottom: 0.8rem;

        &:hover {
            transform: scale(1.03);
        }
    }

    .label2 {
        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40.238px;
        border-radius: 10px;
        background: #000;

        &:hover {
            transform: scale(1.03);
        }
    }
}



.socailtopsectiom {
    // margin-top: 5rem;



    .boxdivnetworks {
        margin-top: 2rem;
        display: flex;
        gap: 1rem;
    }

    .textlight {
        font-family: Montserrat;
        font-size: 22px;
        font-weight: 500;
        line-height: 30.48px;
        text-align: left;
        color: #000000;
        padding-left: 1rem;
        position: relative;
        z-index: 9;

    }

    .smallbox {
        width: 48%;
        height: 231.27px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 15px;
        position: relative;
        overflow: hidden;
        background: #FFFFFF;
        border: 1px solid #E5E5E5;
        cursor: pointer;

        &:before {
            content: "";
            width: 1000px;
            height: 1000px;
            position: absolute;
            left: 50%;
            top: 50%;
            animation: rotating 10s infinite cubic-bezier(0.45, 0, 0.55, 1);
            transform-origin: center;
            background: conic-gradient(#fbd10d, transparent, transparent, rgba(255, 255, 255, 0.3), transparent, transparent, #fbd10d);
        }

        &:after {
            content: "";
            width: calc(100% - 8px);
            height: calc(100% - 8px);
            position: absolute;
            left: 4px;
            top: 4px;
            background: inherit;
            border-radius: .8rem;
            z-index: 1;
        }

        &:hover {
            transform: scale(1.01);
        }

        img {
            height: 100%;
            position: relative;
            z-index: 9;
        }
    }

    @keyframes rotating {
        from {
            transform: translate(-500px, -500px) rotate(0deg);
        }

        to {
            transform: translate(-500px, -500px) rotate(360deg);
        }
    }

    .imgsectiondiv {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 2.5rem;
        margin-top: 2rem;
        // margin-bottom: 3rem;
    }

    .titleboxing {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 300;
        line-height: 22px;
        text-align: left;
        height: 115px;
        color: #000000;
        margin-bottom: 1rem;

    }

    .cardsbox {
        background: #E5E5E54D;
        width: 265px;
        height: 325px;
        border-radius: 15px;
        padding: 2rem 1rem;
        margin-bottom: 1rem;

    }

    .label {
        background: #9EC900;
        height: 30px;
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 700;
        line-height: 25px;
        text-align: center;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        border-radius: 30px;
        justify-content: center;
        cursor: pointer;

        &:hover {
            transform: scale(1.01);
        }
    }

    .headerbox {
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 600;
        line-height: 24.38px;
        text-align: left;
        color: #000000;
        margin-top: 1rem;
        margin-bottom: 1rem;

    }



    .imgsectionbutton {
        border: 1px solid #E5E5E5;
        box-shadow: 0px 2px 2px 0px #00000040;
        width: 315px;
        height: 49px;
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 400;
        line-height: 25px;
        text-align: left;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1.3rem;
        border-radius: 10px;
        transition: all 0.5s ease;
        cursor: pointer;
        font-weight: 500;
        background: #fff;

        &:hover {
            transition: all 0.5s ease;
            // background-color: #FBD10D;
            color: #000;
            // border: 1px solid #FBD10D;

            transform: scale(1.1);
            font-weight: 500;
        }

        svg {
            margin-right: 10px;
        }

    }

    .headeraaa {
        font-family: Montserrat;
        font-size: 28px;
        font-weight: 600;
        line-height: 40px;
        text-align: left;
        color: #000000;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;

    }

    .mainbotcrad {
        display: flex;
        align-items: start;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
        row-gap: 2rem;
    }

    .botcards {
        width: 48%;
        border: 1px solid #E5E5E5;
        padding: 2rem;
        border-radius: 25px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    .botheader {
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        text-align: left;
        color: #000000;
        margin-top: 1.3rem;
    }

    .botpara {
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 300;
        line-height: 25px;
        text-align: left;
        color: #000000;
        height: 149px;
        margin-top: 1.3rem;
    }

    .marketpalcecardsection {
        margin-top: 4rem;
    }

    .thefuture {
        font-family: Montserrat;
        font-size: 22px;
        font-weight: 400;
        line-height: 40px;
        text-align: left;
        color: #000000;
        margin-top: 10px;
    }

    .marketitem {
        width: 47%;
        cursor: pointer;


        img {
            width: 100%;
            height: 100%;
        }

        &:hover {
            transform: scaleY(1.03);
        }
    }

    .colormarket {
        width: 100%;

        width: 410px;
        height: 241px;
        background: #FBD10D26;

        border-radius: 15px;


    }

    .markcardlist {
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 3rem;
    }

    .markettitle {
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 600;
        line-height: 40px;
        text-align: left;
        color: #000;
        margin-bottom: 12px;
        margin-top: 12px;

    }

    .markettext {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 300;
        line-height: 28px;
        text-align: left;
        color: #000;

    }

    .bodypart {
        margin-top: 1.2rem;
        border: 1px solid #E5E5E5;
        padding: 1rem;
        border-radius: 14px;
        cursor: pointer;
        cursor: pointer;
        transition: all 0.5s ease;

        &:hover {
            transform: scale(1.03);
            cursor: pointer;
            transition: all 0.5s ease;
        }
    }

    .pararfafd {
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        margin-top: 13px;
    }

    .listbidy {
        display: flex;
        align-items: start;
        justify-content: space-between;
    }

    .tablessection {
        margin-top: 3REM;
    }

    .lostboy {
        color: #000;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        line-height: 17.07px;
        text-align: left;
        width: 25%;
        display: flex;
        align-items: center;

        svg {
            margin-right: 5px;
        }
    }

    .headerepart {
        display: flex;
        align-items: start;
        justify-content: space-between;
        padding: 0.4rem 1rem;
    }

    .headepart {
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 600;
        line-height: 15.85px;
        text-align: left;
        color: #000000;
        width: 25%;

    }

    .textasd {
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 500;
        line-height: 25px;
        text-align: left;
        color: #000000;
        width: 332px;
        margin-top: 2.5rem;

    }

    .imgsectioncutom {
        border: 1px solid #E5E5E5;
        display: flex;
        align-items: center;
        gap: 5rem;
        border-radius: 25px;
        padding: 2rem;
        margin-top: 1rem;
        border: 1px solid #E5E5E5;
        border-radius: 25px;
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-image: radial-gradient(rgba(0, 0, 0, 0) 89%, #FBD10D 0) 0.01% / 10px;

        .getstarted {
            font-family: Montserrat;
            font-size: 15px;
            font-weight: 700;
            line-height: 25px;
            text-align: center;
            transition: all 0.5s ease;
            width: 200px;
            margin-top: 2.5rem;
            height: 48.76px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            cursor: pointer;
            background-color: #ffff;
            color: #000000;
            border: 1px solid #E5E5E5;

            &:hover {
                transition: all 0.5s ease;
                transform: scale(1.05);
            }
        }

        img {
            width: 40%;
            border-radius: 22px;
        }
    }

    .imgsection {
        display: flex;
        justify-content: space-between;
        margin-top: 2.5rem;

        img {
            width: 48%;
            cursor: pointer;

            &:hover {
                transform: scale(1.01);
            }
        }
    }

    h5 {
        color: #000;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 28px;

        /* 176.471% */
    }

    .borderwidthdata {

        width: 100%;
        margin-top: 4rem;
        margin-bottom: 3.5rem;
    }

    .topcardlist {
        // margin-top: 3.5rem;
        cursor: pointer;
        transition: all 0.5s ease;

        &:hover {
            transition: all 0.5s ease;
            transform: scaleY(1.03);
        }
    }

    h4 {

        color: black;
        font-family: Montserrat;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;

        line-height: normal;
        margin-bottom: 1rem;
        overflow: hidden;
        position: relative;


    }

    .righttextsection {

        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .leftImage {
        border-radius: 15px;
        border: 0.5px solid #cfcab3;
        background: #FFF;
        box-shadow: 0px 4px 4px 0px fdcd3b;
        width: 222.366px;
        height: 222.368px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1.4rem;
        background: #fff;
    }

    .topcardlist {
        display: flex;

    }


    p {
        color: #000;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 28px;
        /* 181.818% */
    }

    h1 {
        color: #000;
        font-family: Montserrat;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 3rem;
    }

    .banner {
        height: 445.463px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
        border: 0.5px solid #E5E5E5;
        background: #000;
    }
}

.startupinstruction {
    img {
        margin-bottom: 1rem;
    }

    svg {
        margin-bottom: 1rem;
    }

    .box1 {
        width: 49.4%;
    }

    .mainsectionbox {
        display: flex;
        flex-wrap: wrap;
        gap: 1.3%;
        border: 1px solid #E5E5E5;
        border-radius: 1rem;
        padding: 2rem 1rem;
        position: relative;
        row-gap: 1.2rem;
    }

    .overlapcolor {

        background: #FBD10D;
        height: 100%;
        width: 360px;
        border-radius: 1rem;
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
    }

    .box {
        box-shadow: 0px 4px 12px 0px #0D0A2C0F;
        border: 1px solid #DEDEE9;
        background: white;
        width: 24%;
        border-radius: 22px;
        height: 340px;
        padding: 27px 17px;
    }

    .headertext {
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        text-align: left;
        color: #000000;
        margin-bottom: 0.5rem;

    }

    .linetextsub {
        font-family: Montserrat;
        font-size: 12px;

        font-weight: 300;
        line-height: 28px;
        text-align: left;
        color: #000000;

    }

    .linetext {
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 300;
        line-height: 28px;
        text-align: left;
        color: #000000;

    }

}

.mobile {
    display: none !important;
}

.mobileh1 {
    display: none;
}

.App-section {
    display: flex;
    align-items: center;

}

.mobiletoggle {
    display: none;
}

.pricetext {
    display: none;
}

@media screen and (min-width:'300px') and (max-width:'900px') {
    .prototypingsection .flex-bottom-sec {
        display: block;
    }

    .whatisIndebox {
        position: relative;
        max-width: 1200px;
        margin: auto;

        background-size: cover;
        background-repeat: no-repeat;
        padding-top: 3rem;
        padding-bottom: 4rem;
        border-radius: 0px;
        width: 100%;
        background: black;
        width: 100%;
        background: black;
        height: 100vh;
        padding: 0 5%;
        padding-top: 151px;

    }

    .pricetext {
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 700;
        line-height: 25px;
        text-align: center;
        color: #000000;
        width: 377px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background: #FBD10D;
        margin-top: 2rem;
        cursor: pointer;
        position: fixed;
        bottom: 0;
        width: 100%;
        border-radius: 0;
        left: 0;
        z-index: 77;
    }

    .lanuchSection .topbox {
        border: 1px solid #E5E5E5;
        border-radius: 15px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
        display: block;
        align-items: center;
        justify-content: space-between;
        padding-left: 1.5rem;
        padding-right: 1rem;
        padding-top: 2.5rem;
        /* padding-bottom: 2.5rem; */
    }

    .lanuchSection .topbox svg {
        width: 85%;
        margin: auto;
    }

    .lanuchSection .cominngsoon {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        line-height: 25px;
        text-align: center;
        width: 200px;
        height: 48.76px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 10px;
        border: 1px solid #E5E5E5;
        margin-top: 10px;
        margin: auto;
    }

    .landingsecondpage .cardss:hover .image {
        position: absolute;
        inset: -0em 0em 0em 0em;
    }

    .landingsecondpage .cardss:hover .img {
        border-radius: 1.2em;
        height: auto;
        transition: all 0.5s ease;
        box-shadow: none;
    }

    .mb-48 {
        margin-bottom: 28px !important;
    }

    .threidsection .customtextcard {
        display: block;
    }

    .threidsection .text-recut {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
        text-align: left;
        color: #000;
        margin-top: 2rem;
    }

    .threidsection .recutext {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 300;
        line-height: 23px;
        text-align: left;
        color: #000;
        margin-top: 0.3rem;
    }

    .threidsection .recutcard {
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
        border: 1px solid #E5E5E5;
        width: 100%;
        border-radius: 15px;
        padding: 1rem;
        margin-bottom: 1rem;
    }

    .threidsection .recutImagesection {
        display: block;

    }

    .threidsection h1 {
        font-family: Montserrat;
        font-size: 22px !important;
        font-weight: 600;
        line-height: 31.67px !important;
        text-align: center !important;
        color: #000;
    }

    .socailtopsectiom .thefuture {

        display: none;
    }

    .socailtopsectiom .mainbotcrad {

        flex-direction: column;
    }

    .socailtopsectiom .botcards {
        width: 100%;
        border: 1px solid #E5E5E5;
        padding: 1rem;
        border-radius: 25px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        height: auto;
    }

    .socailtopsectiom .botheader {
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        text-align: left;
        color: #000000;
        margin-top: 0.3rem;
    }

    .socailtopsectiom .botpara {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 300;
        line-height: 25px;
        text-align: left;
        color: #000000;
        height: auto;
        margin-top: 0.3rem;
    }

    .socailtopsectiom .imgsectioncutom img {
        width: 40%;
        border-radius: 22px;
        display: none;
    }

    .delivarysection .delivarysectionCard {
        width: 100%;
        cursor: pointer;
    }

    .delivarysection {
        margin-top: 1.5rem;
        display: flex;
        align-items: self-start;
        flex-wrap: wrap;
        gap: 2%;
        row-gap: 1.3rem;
        flex-direction: column;
    }

    .protosectiondiv .listofcard .boldheader {
        font-family: Montserrat;
        font-size: 14px;
    }

    .subboldheader {
        font-size: 14px;
    }

    .prototypingsection .weeks {

        font-size: 12px
    }

    .protosectiondiv {
        display: flex;
        align-items: start;
        justify-content: space-between;
        margin-top: 1rem;
    }

    .protosectiondiv .NameSection {
        font-family: Montserrat;
        font-size: 22px;
        margin-bottom: 0.5rem;
    }

    .protosectiondiv .NameSectionsub {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 300;
        line-height: 23px;
        text-align: left;
        color: #000000;
    }

    .protosectiondiv .rigitside {
        width: 100%;
        margin-top: 17px;
    }

    .protosectiondiv .listItems {
        font-family: Montserrat;
        font-size: 14px;
    }

    .protosectiondiv .listofcard .tablelistbody .tableheaderbody:nth-child(1) {
        min-width: 7rem;
    }

    .protosectiondiv .listofcard .tablelist .tableheader:nth-child(1) {
        min-width: 7rem;
    }

    .protosectiondiv .listofcard .tablelist .tableheader {
        min-width: 7rem;
    }

    .protosectiondiv .listofcard .tablelistbody .tableheaderbody {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        line-height: 15.85px;
        text-align: left;
        color: #000000;
        min-width: 7rem;
        border-right: 1px solid #E5E5E5;
        padding: 0.2rem 0.5rem;
    }

    .protosectiondiv .headerlist {
        display: flex;
        align-items: self-start;
        gap: 0.2rem;
        overflow: scroll;
    }

    .socailtopsectiom .headeraaa {
        font-family: Montserrat;
        font-size: 23px;
        font-weight: 600;
        line-height: 40px;
        text-align: left;
        color: #000000;
        margin-top: 1.5rem;
        margin-bottom: 0.5rem;
    }

    .protosectiondiv .textForcard {
        font-family: Montserrat;
        font-size: 17px;
        font-weight: 600;
        line-height: 32px;
    }

    .protosectiondiv .textFounder {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;
        text-align: center;
        color: #000000;
    }

    .protosectiondiv .wiretext {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;
        text-align: left;
        color: #000000;
    }

    .protosectiondiv .cardPrototying {
        background: rgba(229, 229, 229, 0.3019607843);
        border-radius: 15px;
        padding: 1rem;
    }

    .protosectiondiv .right {
        width: 100%;
        padding: 1rem;
        border-image: radial-gradient(rgba(0, 0, 0, 0) 89%, #FBD10D 0) 0.01% / 10px;
        display: none;
    }

    .prototypingsection .getStarted {
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 700;
        line-height: 25px;
        text-align: center;
        color: #fff;
        width: 200px;
        height: 48.76px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        transition: 0.5s;
        margin: auto;
        margin-top: 15px;
    }

    .prototypingsection .dev-text {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 400;
        line-height: 25.38px;
        text-align: center;
        color: #fff;
    }

    .prototypingsection svg {
        margin-bottom: 4rem;
        margin-top: 5rem;
        margin: 2rem auto;
        margin-top: 4rem;
    }

    .prototypingsection .idea {
        font-family: Montserrat;
        font-size: 22px;
        font-weight: 700;
        line-height: 30.48px;
        text-align: center;
        color: #fff;
        margin-bottom: 16px;
    }

    .socailtopsectiom .imgsectioncutom svg {
        border: 1px solid #E5E5E5;
        border-radius: 25px;
        width: 100%;
        height: 13rem;
        padding: 3rem;
        width: 100%;

    }

    .socailtopsectiom .imgsectioncutom {
        width: 100%;
        padding: 1rem !important;
        background: white !important;
        margin: 0 !important;
        border: 0px solid #E5E5E5;
        padding: 0 !important;
        border-radius: 25px;
        box-shadow: none;
        border-image: none;
        height: auto !important;
        display: block !important;
    }

    .mobiletoggle {
        display: block;
        position: relative;
    }

    .socailtopsectiom .textasd {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        line-height: 25px;
        text-align: center;
        color: #000000;
        width: 100% !important;
        margin-top: 1.5rem;
    }

    .socailtopsectiom .markcardlist {
        display: block;
    }

    .socailtopsectiom .marketitem {
        width: 100%;
        cursor: pointer;
    }

    .socailtopsectiom .marketpalcecardsection {
        margin-top: 2rem;
    }

    .socailtopsectiom .markettitle {
        font-family: Montserrat;
        font-size: 22px;
        font-weight: 600;
        line-height: 34px;
        text-align: center;
        color: #000;
        margin-bottom: 12px;
        margin-top: 19px;
    }

    .socailtopsectiom .bodypart {
        margin-top: 0.2rem;
    }

    .socailtopsectiom .tablessection {
        margin-top: 2REM;
    }

    .socailtopsectiom .pararfafd {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 400;
        line-height: 19px;
        text-align: left;
        margin-top: 13px;
    }

    .socailtopsectiom .lostboy {
        color: #000;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        line-height: 17.07px;
        text-align: left;
        width: 25%;
        display: flex;
        align-items: self-start;
        flex-direction: column;
        row-gap: 10px;
    }

    .socailtopsectiom .markettext {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
        text-align: center;
        margin-bottom: 2rem;
        color: #000;
    }

    .socailtopsectiom .colormarket {
        width: 100%;

        width: 100%;
        height: 241px;

        border-radius: 15px;
    }

    .socailtopsectiom .imgsectioncutom .getstarted {
        font-family: Montserrat;
        font-size: 14px;
        margin: auto;
        font-weight: 700;
        line-height: 25px;
        text-align: center;
        transition: all 0.5s ease;
        width: 200px;
        margin-top: 1.5rem;
        height: 48.76px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        cursor: pointer;
        background-color: white;
        color: #000000;
        border: 1px solid #E5E5E5;
    }

    .socailtopsectiom h1 {
        font-size: 22px !important;
        margin-top: 0;
        text-align: center;
        line-height: 32px;
        margin-bottom: 2rem;
    }

    .selectedshowsection {
        padding-top: 44px;
    }

    .tabmobile {
        display: flex;
        align-items: self-start;
        gap: 1.2rem;
        padding: 0 5%;
        border-top: 1px solid #E5E5E5;
        border-bottom: 1px solid #E5E5E5;
        overflow: scroll;
        padding-top: 4px;

        top: 0;
        width: 100%;
        z-index: 9;
        background: white;

        .listtab {
            font-family: Montserrat;
            font-size: 17px;
            font-weight: 400;
            line-height: 25px;
            text-align: center;
            color: #000;
            line-height: 61px;
        }
    }

    .accedemy-section .list {
        border-bottom: 2px solid #E5E5E5;
        padding: 1rem 0;
    }

    .accedemy-section .text svg {
        height: 16px;
    }

    .accedemy-section .carlistacccmendy {
        display: flex;
        flex-direction: column;
        row-gap: 3rem;
        align-items: start;
        gap: 2rem;
        width: 100%;
        padding: 0.5rem 0;
    }

    .accedemy-section .text {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        line-height: 22.48px;
        text-align: left;
        color: #000000;
        cursor: pointer;
        transition: all 0.5s ease;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .chatMain {
        display: none;
    }

    .accedemy-section .jointext {
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 700;
        line-height: 20px;
        text-align: left;
        color: #000000;
        margin-bottom: 2rem;
    }

    .accedemy-section .topcardlist {
        min-width: 100%;
        min-height: 234px;
    }

    .accedemy-section .rightside {
        width: 100%;
        margin-top: 2rem;
    }

    video {
        display: none !important;
    }

    .blacklinek {
        height: 100%;
        width: 100%;
        position: absolute;
        bottom: 0;
        background: #000;
        left: 0;
    }

    .accedemy-section .para {
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 300;
        line-height: 23px;
        text-align: left;
        color: #000000;
    }

    .landingpage {
        position: relative;
        z-index: 2;
    }

    .landingsecondpage {
        position: relative;
        z-index: 2;
    }

    .accedemy-section .price {
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 700;
        line-height: 25px;
        text-align: center;
        color: #000000;
        width: 377px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background: #FBD10D;
        margin-top: 2rem;
        cursor: pointer;
        position: fixed;
        bottom: 0;
        width: 100%;
        border-radius: 0;
        left: 0;
        z-index: 77;
    }

    .accedemy-section {
        display: block;


        width: 100%;
    }

    .accedemy-section .para {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 300;
        line-height: 25px;
        text-align: left;
        color: #000000;
    }

    .accedemy-section .left {
        width: 100%;
    }

    .accedemy-section-main .accndtheader {
        color: #000;
        font-family: Montserrat;
        font-size: 22px;
        text-align: center;
        font-style: normal;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 2rem;
    }

    .accedemy-section-main {
        padding-top: 50px;

    }

    .landingsecondpage .wrapper {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        /* justify-content: center; */
        justify-content: space-between;
        align-items: center;
        /* gap: 2em; */
        margin-block: 4em;
        margin-top: 3rem;
        transition: all 0.5s ease;
        row-gap: 3rem;
    }

    .ai-startup {
        // display: none;
        padding: 0 5%;
    }

    .ai-startup img {
        background: #fff;
        position: static !important;
    }

    .ai-startup .para {
        margin-top: 1rem !important;
        margin-bottom: 2rem !important;
        font-family: Montserrat;
        font-size: 14px !important;
        font-weight: 600;
        line-height: 25px !important;
        text-align: center !important;
        width: 100% !important;
    }

    .ai-startup .inner {
        margin: auto;
        max-width: 1200px;
        position: relative;
        margin: auto;
        max-width: 1200px;
        padding-bottom: 1.2rem !important;
        position: relative;
        padding: 70px 0rem !important;
        padding-top: 50px !important;
        padding-bottom: 0 !important;
    }

    .ai-startup img {

        position: static !important;
        height: 24rem !important;
        width: 100% !important;
    }

    .ai-startup {
        position: relative;

        .inner {
            position: static !important;
        }

    }

    .ai-startup .apply {
        font-family: Montserrat;
        font-size: 16px !important;
        font-weight: 700;
        line-height: 25px;
        text-align: center;
        color: #000 !important;
        width: 328px;
        height: 47.3px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0px !important;
        background: #FBD10D !important;
        cursor: pointer;
        position: absolute;
        bottom: 23px;
        width: 100% !important;
    }

    .ai-startup .text {
        font-family: Montserrat;
        font-size: 27px !important;
        font-weight: 400;
        line-height: 43px !important;
        text-align: center !important;
        color: #000000;
        text-align: center;
    }

    .ai-startup {
        background: #fff !important;
    }

    .landingsecondpage {}

    .landingsecondpage .cardss {
        width: 100%;
    }

    .protosectiondiv .cardMianprototyping {
        display: block;

    }

    .protosectiondiv .leftside {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: self-start;
        gap: 1rem;
    }

    .protosectiondiv {
        .right {
            img {
                display: none;
            }
        }
    }

    .protosectiondiv {
        display: block;
    }

    .protosectiondiv .left {
        width: 100%;
    }

    .mobilebtoom {
        position: absolute;
        bottom: 16px;
        width: 50%;
    }

    .landingsecondpage .topcardsecond {
        width: 100%;

    }

    .landingsecondpage .cardcollect {

        margin-top: 2.6rem;
        row-gap: 1rem;
    }

    .landingsecondpage .inneropage {

        padding: 0 5%;
    }

    .landingsecondpage .textExplore {
        font-family: Montserrat;
        font-size: 22px;
        font-weight: 800;
        line-height: 35px;
        text-align: center;
        color: white;
    }

    .landingsecondpage {
        background: black;
        padding: 50px 0;
    }

    .mobileh1 {
        display: block;
    }

    .deskh1 {
        display: none;
    }

    .ingse {
        display: none;
    }

    .App-section {
        padding: 0 5%;
        position: relative;
    }

    .socailtopsectiom .imgsectionbutton {
        width: 100%;
    }

    .tabLayot .listtext {
        color: #000;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        cursor: pointer;
        line-height: normal;
        line-height: normal;
        width: 80%;
        height: 50px;
        display: flex;
        margin-bottom: 2rem;
        margin-top: 3rem;
    }

    .mb-48 {
        margin-bottom: 1rem;
    }

    .my-80 {
        margin-top: 0;
    }

    .threidsection {
        margin-top: 0;





    }

    .socailtopsectiom .boxdivnetworks {
        margin-top: 2rem;
        display: block;
        gap: 1rem;
    }

    .mobile {
        display: flex !important;
    }

    .socailtopsectiom .cardsbox {

        width: 100%;

    }

    .threidsection .text {

        font-size: 14px;

        width: 100%;

    }

    .mb-40 {
        margin-bottom: 0px;
    }

    .threidsection .bottomDivthird {
        display: none;
    }

    .threidsection .boxthrid {
        box-shadow: 0 0 20px rgba(61, 61, 61, 0.1294117647);
        padding: 0.5rem 1rem;

    }

    .threidsection .lineText {

        font-size: 18px;

    }



    .socailtopsectiom .imgsectiondiv {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 1rem;
        margin-top: 2rem;
        margin-bottom: 0rem;
    }

    .socailtopsectiom .textlight {
        font-size: 18px;

    }

    .socailtopsectiom .topcardlist {
        cursor: pointer;
        margin-top: 2rem !important;
        display: block;
    }

    .socailtopsectiom .smallbox {
        width: 100%;
        height: 200.27px;

    }

    .socailtopsectiom h4 {
        color: #000;
        font-family: Montserrat;
        font-size: 20px;
    }

    .socailtopsectiom .leftImage {

        width: 100px;
        height: 100px;
        margin-bottom: 1.5rem;
        margin-right: 0;

        svg {
            width: 80%;
        }
    }

    .socailtopsectiom .borderwidthdata {
        width: 100%;
        margin-top: 0rem;
        margin-bottom: 2.5rem;
    }

    .accedemy-section .comingsoon {
        font-family: Montserrat;
        font-size: 11px;
    }

    .App-section {
        margin-top: 0 !important;
        padding-bottom: 50px;
        padding-top: 11rem;
        display: block;
    }

    .landingpage .rotatdeteduv {
        width: 100%;
        height: 144px;

    }

    .accedemy-section .sectopnseleted {
        margin-top: 0.8rem;
        margin-bottom: 0rem;
    }

    .ai-startup .apply {
        align-items: center;
        background: #000;
        border-radius: 10px;
        color: #fff;
        cursor: pointer;
        display: flex;
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 700;
        height: 43.3px;
        justify-content: center;
        line-height: 25px;
        text-align: center;
        width: 328px;
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 100% !important;
        border: 0px !important;
    }

    .landingpage .rotatdeteduv iframe {
        width: 100% !important;
        height: 144px;

    }

    .socailtopsectiom {
        margin-top: 0rem !important;
    }

    .ai-startup .apply {
        display: none;
    }

    .socailtopsectiom p {
        color: #000;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 28px;
    }

    .socailtopsectiom h1 {
        font-size: 24px;
        margin-top: 0;
    }

    .startupinstruction .mainsectionbox {
        display: block;
    }

    .startupinstruction .box {

        width: 100%;

        margin-top: 1rem;
    }

    .startupinstruction .overlapcolor {
        display: none;
    }

    .startupinstruction .box1 {
        width: 100%;
    }

    .tabLayot {
        padding-top: 0px;
        display: none;

        .rightsidedrawer {
            width: 100%;
            padding-top: 3px;
        }

        .leftmaindrawer {
            display: none;
        }

    }

    .lastfooter {
        display: none;
    }

    .GasrFbdQYtD26JnmJzJpj {
        display: inline-block;

        border: solid #FBD10D !important;

        padding: 5px !important;
        border: solid #E5E5E5;
        border-width: 0 3px 3px 0 !important;
    }

    ._3kLq2S7iaEfJAxyyJqts35 {
        border: 1px solid #FBD10D !important;
        /* padding: 9px; */
        border-radius: 5rem !important;
        left: 0 !important;
        top: 5% !important;
        height: 29px !important;
        width: 29px !important;
        display: flex !important;
        align-items: center !important;
        padding-left: 6px !important;
        justify-content: center !important;
    }

    ._1-Hp0TfFuBlCxtdPUf_zEF {
        border: 1px solid #FBD10D !important;
        /* padding: 9px; */
        border-radius: 5rem !important;
        right: 0 !important;
        top: 5% !important;
        height: 29px !important;
        width: 29px !important;
        display: flex !important;
        align-items: center !important;
        padding-right: 6px !important;
        justify-content: center !important;
    }

    .landingpage .listimte {
        display: none !important;
        margin-top: 1rem !important;
    }

    .landingpage .linemain {
        display: none;
    }

    .infinite-carousel-arrow {
        display: block !important;
        /* Force it to display */
    }

    .your-arrow-class {
        display: block;
        /* or make sure it's not set to 'none' */
    }

    .infinite-carousel-arrow {
        z-index: 10;
        /* Ensure it is above other elements */
    }

    .landingpage {
        padding-left: 0%;
        padding-right: 0%;
        height: 100vh;
        padding-top: 114px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 0rem;
        padding-bottom: 7rem;
        padding-top: 117px !important;
        background-size: cover;
        background-repeat: no-repeat;



        .textuseai {
            font-family: Montserrat;
            font-size: 22px;
            font-weight: 700;
            line-height: 22px;
            text-align: center;
            color: #FFFFFF;
            margin-bottom: 1rem;

        }



        .svgimage {
            position: absolute;
            bottom: 4.5rem;
            left: 0;
            right: 0;
            margin: auto;
            display: none;
            animation: animateDiv 5s 2;
            animation-direction: alternate;
            animation-iteration-count: infinite;
            z-index: 2;
        }

        @keyframes animateDiv {
            0% {
                bottom: 4.5rem;

            }

            100% {
                bottom: 5rem;

            }
        }



        @keyframes animateDiv {
            0% {
                bottom: 4.5rem;

            }

            100% {
                bottom: 5rem;

            }
        }

        .paragragph {
            font-family: Montserrat;
            font-size: 14px !important;
            font-weight: 500;
            line-height: 24px !important;
            text-align: center;
            color: #fff;
            margin-top: 1rem !important;
            margin-bottom: 1rem !important;
            white-space: pre-wrap;
        }

        .mobileshow {
            display: flex;

            flex-direction: column;
            gap: 0.7rem;

            .list {
                width: 100%;
                height: 54px;
                cursor: pointer;
                border-radius: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #FFFFFF21;
                font-family: Montserrat;
                font-size: 17px;
                font-weight: 700;
                line-height: 40px;
                text-align: center;
                color: #FFFFFF;

            }
        }

        .headertoptex {
            font-family: Montserrat;
            font-size: 23px;
            font-weight: 700;
            line-height: 23px;
            text-align: center;
            color: #fff;
            margin: auto;
            z-index: 2;
            position: relative;
            margin-top: 2px;
        }

        .listcards {

            display: block !important;
            flex-direction: column;
            align-items: self-start;
            row-gap: 0 !important;
        }

        .cardselectiona {
            position: relative;
            z-index: 1;
            width: 100% !important;
            margin-top: 2rem !important;
            transition: transform 500ms linear;
            background: #272626;
        }

        .headerdesk {
            font-family: Montserrat;
            font-size: 14px !important;
            font-weight: 400;
            line-height: 32px;
            text-align: center;
            color: #fff;
            width: 182px !important;
        }

        .headertop {

            font-size: 18px !important;

            line-height: 22px !important;


        }

        h1 {
            font-size: 29px;
            line-height: 40px;
            margin-bottom: 10px;

            div {
                font-weight: 700 !important;
                font-size: 36px !important;
            }
        }

        label {
            font-size: 14px;
            height: 38.732px;
            width: 100%;
        }

        h5 {
            color: #000;
            text-align: center;
            font-family: Montserrat;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 0;
            margin-top: 1rem;
        }

        h2 {

            font-size: 14px;

            margin-top: 0.5rem;
            margin-bottom: 1rem;
        }
    }

}

@media screen and (max-width: 768px) {
    [data-aos] {
        pointer-events: auto !important;
    }

    [data-aos=fade-down-right] {
        transform: translate3d(0px, 0px, 0);
    }

    html:not(.no-js) [data-aos^=fade][data-aos^=fade] {
        opacity: 1 !important;
    }

    html:not(.no-js) [data-aos=flip-right] {

        transform: none !important;
    }
}

// @media screen and (max-width: 768px) {

//     *,
//     *:before,
//     *:after {
//         /*CSS transitions*/
//         transition-property: none !important;
//         /*CSS transforms*/
//         transform: none !important;
//         /*CSS animations*/
//         animation: none !important;
//     }
// }

.shorupansection {
    height: auto;
    display: flex;
    align-items: start;
    justify-content: space-between;
    position: relative;
    padding: 100px 0;

    .svg {
        margin-bottom: 2rem;
    }

    .smallertext {
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
        color: #000;
    }

    .innersectiondata {
        margin: auto;
        max-width: 1200px;
        display: flex;
        align-items: end;
        justify-content: space-between;
        width: 100%;
        height: 49rem;

    }

    img {
        height: auto;
        position: absolute;
        right: 0;
        bottom: 0;
    }

    .inner {
        width: 100%;
        display: flex;
        align-items: self-start;
    }

    .para {
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        text-align: left;
        color: #000000;

    }

    .listec {
        margin-top: 3.5rem;

    }

    .listofitems {
        display: flex;
        align-items: self-start;
        justify-content: space-between;
    }

    .paraalst {
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        text-align: left;
        color: #15182B;
        margin-top: 1rem;

    }

    .textlarger {
        font-family: Montserrat;
        font-size: 45px;
        font-weight: 600;
        line-height: 30px;
        text-align: left;
        color: #15182B;

    }

    .seaallabele1 {
        width: 300px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px;
        border-radius: 10px;
        background: #FBD10D;
        font-family: Montserrat;
        font-size: 22px;
        font-weight: 700;
        line-height: 25px;
        text-align: center;
        color: #000000;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
        }

    }

    .seaallabele {
        width: 150px;
        height: 39px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        border-radius: 10px;

        background: #FBD10D;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 700;
        line-height: 25px;
        text-align: center;
        color: #000000;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
        }

    }

    .insoide {
        position: relative;
        padding: 60px;
        width: 100%;
        overflow: hidden;
        transition: all 2s ease-in-out;
    }

    .breaker {
        margin-top: 2.5rem;
    }

    .registerGarage {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        text-align: center;
        color: black;
    }

    .textsmaller {
        font-family: Montserrat;
        font-size: 35px;
        font-weight: 600;
        line-height: 65px;
        text-align: left;
        color: #000;
    }

    .box {
        width: 100%;
        height: 49rem;
        text-align: center;
        line-height: 150px;

        overflow: hidden;

        cursor: pointer;
        //  position: absolute;
        //  top: 0;
        //left: 0;

        transition: all 0.5s ease;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .sidecontent {
        transition: all 0.5s ease;
        min-width: 100px;
        /* Width of the parent div */
        height: 100%;
        /* Height of the parent div */
        //   position: absolute;
        /* Positioning context for absolute child */

        /* Optional: border for visibility */
        display: flex;
        /* Use flexbox to center content */
        justify-content: center;
        /* Center horizontally */
        align-items: center;
        right: 0;

        top: 0;

        .text {
            transition: all 0.5s ease;
            font-family: Montserrat;
            font-size: 30px;
            font-weight: 700;
            line-height: 30px;
            text-align: center;
            color: #000000;
            transform: rotate(270deg);
            text-align: center;
            white-space: nowrap;
        }
    }

    .header {
        transition: all 0.5s ease;
        font-family: Montserrat;
        font-size: 35px;
        font-weight: 700;
        line-height: 42px;
        text-align: left;
        margin-bottom: 2.5rem;
        text-decoration: underline;
        color: #000000;
    }

    .div1 {
        background-color: #FFE87E;
        transition: all 0.5s ease;
        border-radius: 2rem 0rem 0rem 2rem;
    }

    .div2 {
        background-color: #FFF6CE;
        transition: all 0.5s ease;
    }

    .div3 {
        transition: all 0.5s ease;
        background-color: #FBD10D;
        position: relative;
    }

    .div4 {
        transition: all 0.5s ease;
        background-color: #FFE87E;
    }

    .div5 {
        transition: all 0.5s ease;
        border-radius: 0rem 2rem 2rem 0rem;
        background-color: #FFF6CE;
    }


}









.whyshuld {
    padding: 100px 0;
    border-top: 1px solid #ddd;

    .whyheader {
        font-family: Montserrat;
        font-size: 30px;
        font-weight: 700;
        line-height: 45px;
        text-align: left;
        color: #000000;
    }

    .whycards {
        display: flex;
        align-items: start;
        gap: 7rem;
        margin-top: 8rem;
    }

    .whyrigt {
        position: relative;
        border-radius: 40rem;

        img {
            border-radius: 40rem;
            min-width: 20rem;
            height: 20rem;
        }

        .leftsivg {
            position: absolute;
            position: absolute;
            top: 76px;
            right: -4rem;
            cursor: pointer;

            &:hover {
                transform: scale(1.03);
            }

        }

        .rightsivg {
            position: absolute;
            position: absolute;
            top: 76px;
            left: -4rem;
            cursor: pointer;

            &:hover {
                transform: scale(1.03);
            }

        }
    }

    .inner {
        max-width: 1200px;
        margin: auto;
    }

    .whylabel {
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 700;
        line-height: 25px;
        text-align: center;
        color: #000000;
        width: 210px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 10px;
        background: #FBD10D;
        padding: 0 1rem;
        margin-top: 1.3rem;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
        }

    }

    .whypara {
        font-family: Montserrat;
        font-size: 17px;
        font-weight: 300;
        line-height: 30px;
        text-align: left;
        color: #000000;
    }

    .whyline {
        width: 300px;
        height: 2px;
        margin-top: 1rem;
        margin-bottom: 1rem;
        background: #FBD10D;
    }

    .number {
        font-family: Montserrat;
        font-size: 45px;
        font-weight: 700;
        line-height: 45px;
        text-align: left;
        color: #FBD10D;
        margin-bottom: 0.5rem;

    }

    .title {
        font-family: Montserrat;
        font-size: 45px;
        font-weight: 700;
        line-height: 60px;
        text-align: center;
        color: #000000;

    }
}

.shorupansectionmobile {
    display: none !important;
}

.innerclassssupernova {
    padding-bottom: 5rem;
}

.indainnner {
    max-width: 1200px;
    margin: auto;
}

.superinner {
    max-width: 1200px;
    margin: auto;
}

.supernovaheaderlist {
    justify-content: center !important;
}

.superxutometext {
    text-align: center !important;
    font-size: 40px !important;
    margin-bottom: 4rem !important;
}

.supernovatext {
    .novatext {
        margin-bottom: 2rem;
        font-size: 30px;
        font-weight: 400;
        line-height: 40px;
        text-align: center;
        color: #FFFFFF;
        font-family: Playwrite US Modern !important;
        font-size: 30px;
        font-weight: 400;
        line-height: 40px;
        text-align: center;


    }

    .labelsupernova {
        font-family: Montserrat;
        font-size: 19px;
        font-weight: 700;
        line-height: 25px;
        text-align: center;
        color: #000;
        width: 434px;
        min-height: 61px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background: #FBD10D;
        position: relative;
        top: -8rem;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
        }
    }

    .topsuper {
        padding-bottom: 16rem;
        padding-top: 10rem;
        background-color: #000;
    }

    .innser {
        max-width: 1200px;
        margin: auto;
    }

    .botomsupperNava {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .topdive {
        width: 573px;
        height: 349px;
        position: relative;
        top: -12rem;
        border-radius: 15px;

        iframe {
            border-radius: 15px;
        }

    }

    .novainput {
        font-family: Montserrat;
        font-size: 30px;
        font-weight: 600;
        line-height: 40px;
        text-align: center;
        color: white;
    }

    .novapara {
        font-family: Montserrat;
        font-size: 45px;
        font-weight: 700;
        line-height: 55px;
        text-align: center;
        color: white;
        margin-bottom: 2rem;

        span {
            color: #FBD10D;

        }
    }
}

.svgimagecustom {
    position: absolute;
    bottom: 5.5rem;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
    animation: animateDiv1 5s 2;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    z-index: 3;
}

@keyframes animateDiv1 {
    0% {
        bottom: 3.5rem;

    }

    100% {
        bottom: 4.5rem;

    }
}

.listOfCards {
    max-width: 1200px;
    height: 499px;

    width: 100%;
    margin: auto;

    .innerSectionlist {

        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 499px;
        border-radius: 25px;
    }

    .discription {
        font-family: Montserrat;
        font-size: 17px;
        font-weight: 300;
        line-height: 30px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #000000;
        margin-bottom: 2rem;
    }

    .readerlael {
        font-family: Montserrat;
        font-size: 17px;
        font-weight: 600;
        line-height: 43px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #000000;
        border-bottom: 1px solid #FBD10D;
        width: max-content;
        cursor: pointer;
        transition: all .25s ease-in-out;
        -moz-transition: all .25s ease-in-out;
        -webkit-transition: all .25s ease-in-out;

        &:hover {
            transition: all .25s ease-in-out;
            -moz-transition: all .25s ease-in-out;
            -webkit-transition: all .25s ease-in-out;
            transform: scale(1.03);
        }

    }

    .headerTitle {
        font-family: Montserrat;
        font-size: 30px;
        font-weight: 600;
        line-height: 43px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #000000;
        margin-bottom: 2rem;

    }

    .RightssideSlider {
        width: 50%;
        height: 100%;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0px 25px 25px 0px;

        img {
            height: 70px;
            width: inherit !important;
        }
    }

    .carousel.carousel-slider .control-arrow {
        top: 50%;
        color: #fff;
        font-size: 26px;
        bottom: 0;
        margin-top: 0;
        padding: 5px;
        background: white !important;
        opacity: 1 !important;
        height: 50px;
        width: 50px;
        border-radius: 50px;
    }

    .carousel .slider-wrapper {
        border-radius: 25px;
    }

    .carousel .control-next.control-arrow {
        right: -60px;
    }

    .carousel .control-prev.control-arrow:before {
        border-right: 8px solid #000 !important;
    }

    .carousel .control-next.control-arrow:before {
        border-left: 8px solid #000 !important;
    }

    .carousel .control-prev.control-arrow {
        left: -60px;
    }

    .carousel.carousel-slider {
        position: relative;
        margin: 0;
        overflow: visible !important;
    }

    .leftsideSlider {
        width: 50%;
        background: white;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 2rem;
        border-radius: 25px 0px 0 25px;

    }
}

@media screen and (min-width:'300px') and (max-width:'900px') {
    .supernovatext .botomsupperNava {
        background: black;
    }

    .whylabelEnrolNow {
        display: none;
    }

    .svgimagecustom {
        display: none;
    }

    .superxutometext {
        text-align: center !important;
        font-size: 32px !important;
        margin-bottom: 4rem !important;
    }


    .innerclassssupernova {
        padding-bottom: 5rem;
        padding: 2rem 5%;
    }

    .supernovatext .topsuper {
        padding-bottom: 2rem;
        padding-top: 10rem;
        padding-left: 5%;
        padding-right: 5%;
        background-color: #000;
    }

    .supernovatext {}

    .supernovatext .labelsupernova {
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 700;
        line-height: 25px;
        text-align: center;
        color: #000;
        width: 86%;
        min-height: 49px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background: #FBD10D;
        position: static;
        top: -8rem;
        margin-top: 2rem;
        margin-bottom: 3rem;
    }

    .supernovatext .novapara {
        font-family: Montserrat;
        font-size: 30px;
        font-weight: 700;
        line-height: 44px;
        text-align: center;
        color: white;
        margin-bottom: 2rem;
    }

    .supernovatext .novatext {
        margin-bottom: 2rem;
        font-size: 24px;
        font-weight: 400;
        line-height: 31px;
        text-align: center;
        color: #FFFFFF;
    }

    .supernovatext .novainput {
        font-family: Montserrat;
        font-size: 22px;
        font-weight: 600;
        line-height: 31px;
        text-align: center;
        color: white;
    }

    .supernovatext .topdive {
        width: 100%;
        height: 300px;
        position: static;
        top: -12rem;
        border-radius: 15px;
    }

    .landingpage .inner {
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .supernovatext .topdive iframe {
        border-radius: 15px;
        width: 100% !important;
        height: 300px !important;
    }

    .whyshuld {

        // display: none;
        &:nth-child(1n) {}
    }

    .whyshuld .whyrigt {
        position: relative;
        margin: auto;
        text-align: center;
    }

    .whyshuld .number {
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 700;
        line-height: 40px;
        text-align: center;
        color: #FBD10D;
        margin-bottom: 0.8rem;
    }

    .whyshuld .whyline {
        margin: auto;
        width: 50%;
        height: 2px;
        margin-top: 1rem;
        margin-bottom: 1rem;
        background: #FBD10D;
    }

    .whyshuld .whypara {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 300;
        line-height: 25px;
        text-align: center;
        color: #000000;
    }

    .whyshuld .whylabel {

        display: none;
    }

    .whyshuld .whyheader {
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        line-height: 27px;
    }

    .whyshuld .whyrigt img {
        min-height: 200px;
        min-width: auto;
        height: 150px;
        margin: auto;
    }

    .whyshuld .whyrigt .leftsivg {
        position: absolute;
        position: absolute;
        top: 112px;
        right: -2rem;
        cursor: pointer;
        height: 6rem;
    }

    .whyshuld .whyrigt .rightsivg {
        position: absolute;
        position: absolute;
        top: 112px;
        left: -2rem;
        cursor: pointer;
        height: 6rem;
    }

    .whyshuld .whycards {
        display: flex;
        align-items: start;
        margin-top: 2rem;
        gap: 1rem;
        flex-direction: column;
    }

    .whyshuld .title {
        font-family: Montserrat;
        font-size: 25px;
        font-weight: 700;
        line-height: 39px;
        text-align: center;
        color: #000000;
    }

    .whyshuld {
        padding: 50px 5%;
        border-top: 1px solid #ddd;
    }

    .reverserdirectiion {
        flex-direction: column-reverse !important;
    }

    // whyshuld .whycards {
    //     display: flex;
    //     align-items: start;
    //     gap: 7rem;
    //     margin-top: 8rem;

    // }

    .shorupansection {
        display: none !important;
    }

    .shorupansectionmobile {
        display: block !important;
    }



    .shorupansection {
        padding: 0;
    }

    .shorupansection .listec {
        margin-top: 2.5rem;
    }

    .shorupansection .para {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        line-height: 26px;
        margin-right: 0 !important;
    }

    .shorupansection .svg {
        margin: auto;

        margin-bottom: 1.5rem !important;

        height: 53px;

    }

    .shorupansection .seaallabele {
        display: none;
    }

    .imgse {
        display: block !important;
        position: static !important;
        margin: auto !important;
        height: 150px !important;
        margin-bottom: 1.5rem !important;
    }

    .shorupansection img {
        height: auto;
        position: absolute;
        right: 0;
        bottom: 0;
        display: none;
    }

    .shorupansection .smallertext {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: #000;
    }

    .shorupansection .seaallabele1 {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        padding: 0 30px;
        border-radius: 10px;
        background: #FBD10D;
        font-family: Montserrat;
        font-size: 19px;
        font-weight: 700;
        line-height: 25px;
        text-align: center;
        color: #000000;
        cursor: pointer;
    }

    .shorupansection .textsmaller {
        font-family: Montserrat;
        font-size: 22px;
        font-weight: 600;
        line-height: 33px;
        text-align: center;
        color: #000;
        margin-bottom: 1rem;
    }

    .shorupansection .box {
        width: 100%;
        height: auto;
        text-align: center;
        line-height: 150px;
        cursor: pointer;
        transition: all 0.5s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem 0;
    }

    .shorupansection .textlarger {
        font-family: Montserrat;
        font-size: 34px;
        text-align: center;
    }

    .shorupansection .listofitems {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        flex-direction: column;
        justify-content: center;
    }

    .shorupansection .paraalst {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;
        text-align: center;
        color: #15182B;
        margin-top: 1rem;
    }

    .shorupansection .sidecontent .text {
        transition: all 0.5s ease;
        font-family: Montserrat;
        font-size: 21px;
        font-weight: 700;
        line-height: 30px;
        text-align: center;
        color: #000000;
        transform: rotate(0deg);
        text-align: center;
        white-space: nowrap;
    }

    .shorupansection .header {
        transition: all 0.5s ease;
        font-family: Montserrat;
        font-size: 22px;
        font-weight: 600;
        line-height: 30px;
        text-align: left;
        margin-bottom: 1.2rem;
        text-decoration: underline;
        color: #000000;
        text-align: center;
    }

    .shorupansection {
        height: auto;
    }

    .shorupansection .insoide {
        position: relative;
        padding: 0 5%;
    }

    .shorupansection .breaker {
        margin-top: 1.5rem;
    }

    .shorupansection .para {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
        text-align: center;
    }

    .shorupansection .div1 {
        background-color: #FFE87E;
        transition: all 0.5s ease;
        border-radius: 0rem;
    }

    .shorupansection .inner {
        width: 100%;
        display: block;
        align-items: self-start;
    }

    .shorupansection .div5 {
        border-radius: 0rem;
    }

    .shorupansection .innersectiondata {

        height: auto;
    }

}
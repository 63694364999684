.landinggpt {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100vh;

    .showtext {
        margin-bottom: 1.5rem;
    }

    h1 {
        color: #18191D;
        text-align: center;
        font-family: Montserrat;
        font-size: 35px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
        /* 114.286% */
    }

    h2 {

        color: #18191D;
        text-align: center;
        font-family: Montserrat;
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px;
        /* 114.286% */
    }



    p {
        color: #18191D;
        text-align: center;
        font-family: Montserrat;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px;
        /* 133.333% */
        position: absolute;
        bottom: 1.5rem;
        left: 0;
        right: 0;
        margin: auto;

    }
}

.mobilelandinggpt {
    display: none;
}

@media screen and (max-width: 992px) {
    .landinggpt {
        display: none;
    }

    .mobilelandinggpt {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100vh;
        padding: 0 4%;

        .showtext {
            margin-bottom: 1.5rem;
        }

        span {
            font-weight: bold;
        }

        h1 {
            color: #18191D;
            text-align: center;
            font-family: Montserrat;
            font-size: 35px;
            font-style: normal;
            font-weight: 600;
            line-height: 40px;
            /* 114.286% */
        }

        h2 {

            color: #18191D;
            text-align: center;
            font-family: Montserrat;
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            line-height: 40px;
            /* 114.286% */
        }



        p {
            color: #18191D;
            text-align: center;
            font-family: Montserrat;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 40px;
            /* 133.333% */
            position: absolute;
            bottom: 1.5rem;
            left: 0;
            right: 0;
            margin: auto;

        }
    }


}
.flashscreen {
    .carlist {
        width: 33.333%;
        height: 40vh;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        transition: all .50s ease-in-out;
        -moz-transition: all .50s ease-in-out;
        -webkit-transition: all .50s ease-in-out;
        overflow: hidden;

        svg {
            margin-top: 1rem;
            position: absolute;
            z-index: 3;
            bottom: -5rem;
            transition: all .50s ease-in-out;
            -moz-transition: all .50s ease-in-out;
            -webkit-transition: all .50s ease-in-out;

        }

        &:hover {
            .imgsectfile{
                transition: all .50s ease-in-out;
                -moz-transition: all .50s ease-in-out;
                -webkit-transition: all .50s ease-in-out;
                animation: imageTransformAnimationx 10s infinite alternate;
                -webkit-transform: scale(1) rotate(0deg);
                -moz-transform: scale(1) rotate(0deg);
                 -o-transform: scale(1) rotate(0deg);
                    transform: scale(1) rotate(0deg);
            }
          svg {
            transition: all .50s ease-in-out;
            -moz-transition: all .50s ease-in-out;
            -webkit-transition: all .50s ease-in-out;
            margin-top: 1rem;
            position: static;
            z-index: 3;
            bottom: -5rem;
            transform: scale(1.03);

        }
            .textflash {
                transition: all .50s ease-in-out;
                -moz-transition: all .50s ease-in-out;
                -webkit-transition: all .50s ease-in-out;
                transform: scale(1.03);
            }
        }
    }

    .topsection {
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .carlistcustom {
        width: 66.67%;
        height: 40vh;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
        cursor: pointer;
        transition: all .50s ease-in-out;
        -moz-transition: all .50s ease-in-out;
        -webkit-transition: all .50s ease-in-out;

        overflow: hidden;
      
        svg {
            margin-top: 1rem;
            position: absolute;
            z-index: 3;
            bottom: -5rem;
            transition: all .50s ease-in-out;
            -moz-transition: all .50s ease-in-out;
            -webkit-transition: all .50s ease-in-out;

        }

        &:hover {
            .imgsectfile{
                transition: all .50s ease-in-out;
                -moz-transition: all .50s ease-in-out;
                -webkit-transition: all .50s ease-in-out;
                animation: imageTransformAnimationx 10s infinite alternate;
                -webkit-transform: scale(1) rotate(0deg);
                -moz-transform: scale(1) rotate(0deg);
                 -o-transform: scale(1) rotate(0deg);
                    transform: scale(1) rotate(0deg);
            }
          svg {
            transition: all .50s ease-in-out;
            -moz-transition: all .50s ease-in-out;
            -webkit-transition: all .50s ease-in-out;
            margin-top: 1rem;
            position: static;
            z-index: 9;
            bottom: -5rem;
            transform: scale(1.03);

        }
            .textflash {
                transition: all .50s ease-in-out;
                -moz-transition: all .50s ease-in-out;
                -webkit-transition: all .50s ease-in-out;
                transform: scale(1.03);
            }
        }
    }

    .imgsectfile {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1;
        animation: imageTransformAnimation 10s infinite alternate;
        transition: all .50s ease-in-out;
        -moz-transition: all .50s ease-in-out;
        -webkit-transition: all .50s ease-in-out;
    }
    @keyframes imageTransformAnimation1{
        0% {
          -webkit-transform: scale(1) rotate(0deg);
             -moz-transform: scale(1) rotate(0deg);
              -o-transform: scale(1) rotate(0deg);
                 transform: scale(1) rotate(0deg);
        //   -webkit-filter: hue-rotate(0deg);
        //      -moz-filter: hue-rotate(0deg);
        //       -o-filter: hue-rotate(0deg);
        //          filter: hue-rotate(0deg);
        }
        100% {
          -webkit-transform: scale(1.5) rotate(25deg);
             -moz-transform: scale(1.5) rotate(25deg);
              -o-transform: scale(1.5) rotate(25deg);
                 transform: scale(1.5) rotate(25deg);
        //   -webkit-filter: hue-rotate(360deg);
        //      -moz-filter: hue-rotate(360deg);
        //       -o-filter: hue-rotate(360deg);
        //          filter: hue-rotate(360deg);
        }
      }
        
    .middleSection {
        height: 20vh;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            height: 15vh;
        }

    }

    .textflash {
        font-family: Montserrat;
        font-size: 30px;
        font-weight: 600;
        line-height: 55px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #FFFFFF;
        z-index: 9;
        position: relative;
    }

    .blackscreen {
        background: #000000bd;
        // border-radius: 30px;
        height: 100%;
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 2;
    }

}


@media screen and (min-width:'300px') and (max-width:'900px') {
    .flashscreen {
        .carlist {
            width: 100%;
            height: 300px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
                .textflash {
                    // transform: scale(1.03);
                }
            }
        }

        .middleSection {
            display: none;
        }

        .topsection {
            display: block;
        }

        .carlistcustom {

            width: 100%;
        }
    }



}
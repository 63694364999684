.sectonsix {

    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .innersix {
        max-width: 1200px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 3rem 0rem;
        border-radius: 32px;
    }

    .linetext {
        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 43px;
        width: 48%;
        text-align: center;
        margin-bottom: 4rem;
    }

    .sixSectioncards {
        display: flex;
        align-items: start;
        justify-content: space-between;
        width: 80%;
    }

    .sixcards {
        width: 30%;
        cursor: pointer;

        &:hover {
            transform: scale(1.02);
        }

        img {
            width: 100%;
            border-radius: 32px;
            margin-bottom: 1rem;
        }
    }

    .cardpara {
        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin-top: 10px;
        text-align: center;
    }

    .cardheader {
        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 23px;

        text-align: center;
    }
}
.sectionthree {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    padding-top: 7rem;
    padding-bottom: 7rem;

    .innertextline {
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 600;
        line-height: 22px;
        text-align: left;
        margin-top: 0.3rem;
        color: #FFFFFF;
    }

    .cartlist {
        border: 1px solid #FFFFFF;
        background: #00000085;
        border-radius: 35px;
        display: flex;
        align-items: center;
        padding: 1rem;
        width: 28rem;
        grid-gap: 1.3rem;
        gap: 1.5rem;
        margin-right: 14px;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        cursor: pointer;

        &:hover {
            transform: scale(1.02);
        }


        img {
            height: 10rem;
        }

    }

    .inner {
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .cardtexthderd {
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        text-align: left;
        color: #FFFFFF;
        margin-bottom: 10px;
    }

    .headertext {
        font-family: Montserrat;
        font-size: 40px;
        font-weight: 700;
        line-height: 85px;
        text-align: center;
        margin-bottom: 5rem;
        color: white;
    }


}


@media screen and (min-width:'300px') and (max-width:'900px') {

    .sectionthree {
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 0;
        padding-bottom: 0rem;
    }

    .sectionthree .cardtexthderd {
        font-family: Montserrat;
        font-size: 13px;
    }

    .sectionthree .cartlist img {
        height: 6rem;
    }

    .sectionthree .cardtexthderd {
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 700;
        line-height: 20px;
        text-align: left;
        color: #fff;
        margin-bottom: 0px;
    }

    .sectionthree .cartlist {
        border: 1px solid #fff;
        background: rgba(0, 0, 0, .5215686274509804);
        border-radius: 35px;
        display: flex;
        align-items: center;
        padding: 0.5rem;
        width: 19rem;

        gap: 0.5rem;
        margin-right: 14px;
        margin-top: 0.5rem;
        margin-bottom: .5rem;
    }

    .sectionthree .innertextline {
        font-family: Montserrat;
        font-size: 9px;
        font-weight: 600;
        line-height: 18px;
    }

    .sectionthree .headertext {
        font-family: Montserrat;
        font-size: 29px;
        font-weight: 700;
        line-height: 54px;
        text-align: center;
        margin-bottom: 2rem;
        color: #fff;
    }
}
.sectonsaven {

    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .innersaven {
        max-width: 1200px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 5rem 0rem;
        border-radius: 32px;
    }

    .subline {
        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        width: 48%;
        text-align: center;
        margin-bottom: 4rem;
        margin-top: 1rem;
    }

    .linetext {
        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 43px;
        width: 48%;
        text-align: center;

    }

    .savenSectioncards {
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 1rem;
    }

    .savencards {
        width: 32%;
        cursor: pointer;

        &:hover {
            transform: scale(1.02);
        }

        img {
            width: 100%;
            border-radius: 22px;
            margin-bottom: 1rem;
        }
    }

    .cardpara {
        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin-top: 10px;
        text-align: center;
    }

    .cardheader {
        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 23px;

        text-align: center;
    }
}
.navbar {

  height: 82px;
  background: #000;

  position: fixed;
  width: 100%;
  z-index: 99;
  top: 40px;
  border-bottom: 5px solid #FBD10D;

  .mobileright {
    display: none;
  }

  .innside-nav {
    max-width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .left {
    display: flex;
    align-items: center;

  }

  svg {
    transition: transform 500ms linear;
    cursor: pointer;

    &:hover {
      transform: scale(1.03);
      transition: transform 500ms linear;
    }
  }

  .whiteline {
    height: 58px;
    width: 1px;
    background: white;
    margin: 0rem 15px;
  }

  .drodwonlistcustom {
    border: 1px solid #E5E5E5;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
    background: white;
    padding: 1rem;
    position: absolute;
    z-index: 999;
    top: 4.2rem;
    width: 100%;
    left: 0;
    display: flex;
    flex-direction: column;

    align-items: start;
    border-radius: 0px;
    min-height: 100%;
    max-height: 83vh;
    overflow: scroll;
    padding-bottom: 1rem;

    .seeallproduct {
      font-family: Montserrat;
      cursor: pointer;
      font-size: 15px;
      font-weight: 400;
      line-height: 18.29px;
      text-align: left;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-underline-position: from-font;
      text-decoration-skip-ink: auto;
      text-decoration: underline;
      color: #000000;
      display: flex;
      align-items: center;
      gap: 5px;

      &:hover {
        transform: scale(1.02);
      }
    }

    .cardlistitem {
      display: flex;
      align-items: center;
      padding: 1rem;
      gap: 16px;
      width: 495px;
      box-shadow: 0px 2px 2px 0px #00000040;
      border: 1px solid #E7E7E7;
      border-radius: 15px;
      cursor: pointer;

      &:hover {
        transform: scale(1.02);
      }

    }

    .de-global {
      font-family: Montserrat;
      font-size: 11px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #000000;
    }

    .title-global {
      font-family: Montserrat;
      font-size: 15px;
      font-weight: 600;
      line-height: 18.29px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #000000;
    }

    .line {
      min-height: 100%;
      width: 1px;
      background: #E7E7E7;

    }

    .dorpinnerdata {
      max-width: 1200px;
      width: 100%;
      display: flex;
      // align-items: self-start;
      justify-content: space-between;
      margin: auto;
      min-height: 100%;
    }

    .header {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 600;
      line-height: 18.29px;
      text-align: left;
      color: #000;
      margin-bottom: 0.5rem;
    }

    .listSleectedcustom {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: self-start;
    }

    .listSleected {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      align-items: self-start;
    }

    .style-11 {
      display: flex;
      align-items: center;
      padding: 10px 10px;
      position: relative;
      cursor: pointer;
      // background: red;
      min-width: 16rem;
      border-radius: 10px;
      /* button */
      // &:hover{
      //   background: #0000000F;

      // }
      .link {
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 600;
        line-height: 18.29px;
        text-align: left;
        cursor: pointer;

        color: black;

        position: relative;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        border: 0;
        padding-left: 13px;
        left: 0;
        line-height: auto;
        overflow: hidden;

        /*line*/
        &:before {
          content: "";
          background-color: #FBD10D;
          width: 3px;
          height: 100%;
          position: absolute;
          z-index: 2;
          left: 0px;
          top: -1px;
          border-radius: 50px;
          transition: all 0.3s ease-in-out;
        }

        /*arrow*/
        &:after {
          content: "";
          width: 15px;
          height: 15px;
          display: flex;
          align-items: center;
          background-color: transparent;
          position: absolute;
          border: solid 3px #FBD10D;
          border-left: 0;
          border-bottom: 0;
          top: calc(50% - 9px);
          border-radius: 2px;
          transform: translateX(-42px) rotate(45deg);
          transition: all 0.3s 0.2s ease-in-out;
        }
      }

      /* bg button */
      &:before {
        content: "";
        //  background-color: white;
        width: 0;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0px;
        border-radius: 3px;
        transition: all 0.4s 0.3s ease-in-out;
      }

      &:hover {
        &:before {
          width: 100%;
          height: 100%;
          left: 0;
          bottom: 0px;
        }

        .link {
          padding-left: 40px;


          &:before {
            left: 17px;

            transform: rotate(90deg);
          }

          &:after {
            transform: translate(-21px) rotate(45deg);
          }
        }
      }
    }


  }


  .drodwonlist {
    border: 1px solid #E5E5E5;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
    background: white;
    padding: 1rem;
    position: absolute;
    z-index: 999;
    top: 3.8rem;
    width: 17rem;

    display: flex;
    flex-direction: column;

    align-items: start;
    border-radius: 15px;

    .header {
      font-family: Montserrat;
      font-size: 15px;
      font-weight: 600;
      line-height: 18.29px;
      text-align: left;
      color: #000;
      margin-bottom: 0.5rem;
    }

    .style-11 {
      display: flex;
      align-items: center;
      padding: 10px 0px;
      position: relative;
      cursor: pointer;

      /* button */

      .link {
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 400;
        line-height: 18.29px;
        text-align: left;
        cursor: pointer;

        color: black;
        color: #4c6082;
        position: relative;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        border: 0;
        padding-left: 13px;
        left: 0;
        line-height: auto;
        overflow: hidden;

        /*line*/
        &:before {
          content: "";
          background-color: #FBD10D;
          width: 3px;
          height: 100%;
          position: absolute;
          z-index: 2;
          left: 0px;
          top: -1px;
          border-radius: 50px;
          transition: all 0.3s ease-in-out;
        }

        /*arrow*/
        &:after {
          content: "";
          width: 15px;
          height: 15px;
          display: flex;
          align-items: center;
          background-color: transparent;
          position: absolute;
          border: solid 3px #FBD10D;
          border-left: 0;
          border-bottom: 0;
          top: calc(50% - 9px);
          border-radius: 2px;
          transform: translateX(-42px) rotate(45deg);
          transition: all 0.3s 0.2s ease-in-out;
        }
      }

      /* bg button */
      &:before {
        content: "";
        background-color: white;
        width: 0;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0px;
        border-radius: 3px;
        transition: all 0.4s 0.3s ease-in-out;
      }

      &:hover {
        &:before {
          width: 100%;
          height: 100%;
          left: 0;
          bottom: 0px;
        }

        .link {
          padding-left: 40px;


          &:before {
            left: 17px;

            transform: rotate(90deg);
          }

          &:after {
            transform: translate(-21px) rotate(45deg);
          }
        }
      }
    }

    // div {
    //   font-family: Montserrat;
    //   font-size: 15px;
    //   font-weight: 400;
    //   line-height: 18.29px;
    //   text-align: left;
    //   cursor: pointer;

    //   color: black;

    //   &:before {
    //     content: "";
    //     background-color: #FBD10D;
    //     width: 3px;
    //     height: 100%;
    //     position: absolute;
    //     z-index: 2;
    //     left: 0px;
    //     top: 0px;
    //     border-radius: 50px;
    //     transition: all 0.3s ease-in-out;
    //   }

    //   &:after {
    //     content: "";
    //     width: 15px;
    //     height: 15px;
    //     display: flex;
    //     align-items: center;
    //     background-color: transparent;
    //     position: absolute;
    //     border: solid 3px #FBD10D;
    //     border-left: 0;
    //     border-bottom: 0;
    //     top: calc(50% - 9px);
    //     border-radius: 2px;
    //     transform: translateX(-42px) rotate(45deg);
    //     transition: all 0.3s 0.2s ease-in-out;
    //   }

    //   &:hover {
    //     transform: scale(1.03);
    //   }
    // }
  }

  .text {
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    margin-right: 28px;
    font-weight: 600;
    line-height: 25px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.4rem;
    position: relative;
    transition: transform 500ms linear;

    &:hover {
      transition: transform 500ms linear;
      transform: scale(1.03);

      svg {
        transition: transform 500ms linear;
        transform: rotate(180deg);
      }


    }
  }

  .ttruelabel {

    background: #FBD10D;
    width: 100%;
    height: auto;
    border-radius: 0 0 35px 35px;
    position: absolute;
    left: 0;
    z-index: 9999999;
    top: 36px;
    padding: 0 1rem;
    padding-bottom: 1rem;

    .listofitembutton {
      font-family: Montserrat;
      font-size: 15px;
      font-weight: 700;
      line-height: 25px;
      text-align: left;
      color: #fff;
      background: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      cursor: pointer;
      transition: transform 250ms linear;
      width: 100%;
      border-radius: 3rem;

      &:hover {
        transition: transform 250ms linear;
        transform: scale(1.03);
        // box-shadow: -1px 0px 14px 6px #fff8f873;
      }
    }

    .listofitem {
      font-family: Montserrat;
      font-size: 15px;
      margin-bottom: 12px;
      font-weight: 700;
      line-height: 25px;
      text-align: left;
      color: #000;
      transition: transform 250ms linear;

      &:hover {
        transition: transform 250ms linear;
        transform: scale(1.03);
        // box-shadow: -1px 0px 14px 6px #fff8f873;
      }
    }

    &:hover {
      transition: transform 250ms linear;
      // transform: scale(1.03);
      // box-shadow: -1px 0px 14px 6px #fff8f873;
    }
  }

  label {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    width: 166.637px;
    height: 37.052px;
    flex-shrink: 0;
    margin-bottom: 0;
    border-radius: 35px;
    background: #FBD10D;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    padding: 0 1rem;
    z-index: 1;
    transition: transform 500ms linear;
    background: #FBD10D;
    position: relative;
    // &:before {
    //   content: '';
    //   position: absolute;
    //   bottom: 0;
    //   left: 20px;
    //   width: 0;
    //   height: 0;
    //   /*    background: #F00;*/
    //   z-index: -1;
    //   transition: 1s;
    //   border-style: solid;
    //   border-color: #FBD10D;
    //   border-width: 80px 100px;
    //   transform: rotate(360deg);
    //   transform-origin: top left;

    //   color: #000;
    // }

    // &:hover:before {
    //   border-color: #000;
    //   transform: rotate(44deg);
    //   color: white;
    // }

    // &:hover {
    //   color: #FBD10D;

    //   transform: scale(1.03);

    //   border: 2px solid #ffff;
    // }

    &:hover {
      transition: transform 250ms linear;
      transform: scale(1.03);
      // box-shadow: -1px 0px 14px 6px #fff8f873;
    }
  }
}

.flotingtop {
  display: none;
}

.toggledata {
  display: none;
}

.svgtoggle {
  display: none;
}

.listofdata {
  display: none;
}

@media screen and (min-width:'300px') and (max-width:'900px') {


  .navbar .drodwonlist {
    border: 0px solid #E5E5E5;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2509803922);
    background: white;
    padding: 1rem;
    position: static;
    z-index: 999;
    top: 3.8rem;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
    border-radius: 15px;
  }

  .listofdata {
    background: #FBD10D;
    padding-left: 5%;
    padding-right: 5%;
    position: fixed;
    width: 100%;
    transition: all 0.5s ease;
    top: 34px;
    z-index: 100;
    padding-bottom: 12px;
    display: block;


    .flotingtops {
      color: #000;
      text-align: center;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      margin-right: 28px;
      font-weight: 700;
      line-height: 25px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.4rem;
      position: relative;
      transition: transform 500ms linear;
      margin-top: 12px;

    }
  }

  .flotingtop {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    margin-right: 28px;
    font-weight: 700;
    line-height: 25px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.4rem;
    position: relative;
    transition: transform 500ms linear;
    padding: 0.5rem;
    background: #FBD10D;
    padding-left: 5%;
    padding-right: 5%;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 99;
  }

  .toggledata {
    transition: all 0.5s ease;
    height: calc(100vh - 115px);
    background: white;
    z-index: 99;
    top: 115px;
    width: 100%;
    right: 0;
    position: fixed;

    display: block;
    padding-top: 8px;

    .labelmobile {
      background: #FBD10D;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      border-radius: 3rem;
      width: 96%;
      font-size: 13px;
      font-weight: 700;
      position: absolute;
      bottom: 2rem;
      left: 0;
      right: 0;
      margin: auto;
    }

    .limitdata {
      height: calc(100% - 1rem);
      overflow: scroll;
      padding-top: 2rem;
    }

    .cardlist {
      width: 100%;
      display: flex;
      align-items: start;
      border-top: 1px solid #E5E5E5
    }

    .box {
      width: 50%;
      height: 10rem;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .alerttext {
      margin-top: 1rem;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 400;
      line-height: 19.5px;
      text-align: center;
      color: black;

    }

    .textheader {
      font-family: Montserrat;
      font-size: 25px;
      font-weight: 600;
      line-height: 35px;
      text-align: center;
      color: black;
      padding: 0 5%;
      margin-bottom: 2rem;
    }

    .mobiletext {
      color: #000;
      text-align: center;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      padding-left: 3%;
      padding-right: 3%;
      font-weight: 500;
      line-height: 25px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.4rem;
      position: relative;
      transition: transform 500ms linear;
      padding: 1.5rem;
      border-bottom: 2px solid #dee2e6;

    }

    .innertext {
      color: #000;
      display: flex;
      font-size: 14px;
      font-weight: bold;
      align-items: center;
      text-decoration: underline;

      svg {
        display: none;
      }
    }
  }

  .navbar {
    padding-left: 5%;
    padding-right: 5%;
    top: 39px;

    .mobileright {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    .alumni {
      font-family: Montserrat;
      font-size: 15px;
      font-weight: 800;
      line-height: 25px;
      text-align: center;
      color: #000000;
      background: #FBD10D;
      display: inline-block;
      border-radius: 5px;
      -webkit-animation: glowing 1500ms infinite;
      -moz-animation: glowing 1500ms infinite;
      -o-animation: glowing 1500ms infinite;
      animation: glowing 1500ms infinite;
      width: 90px;
      height: 29px;
      align-items: center;
      display: flex;
      justify-content: center;
      position: absolute;
      right: 4rem;
    }

    @keyframes glowing {
      0% {
        background-color: #FBD10D;
        -webkit-box-shadow: 0 0 3px #FBD10D;
      }

      50% {
        background-color: #eedf99;
        -webkit-box-shadow: 0 0 40px #eedf99;
      }

      100% {
        background-color: #FBD10D;
        -webkit-box-shadow: 0 0 3px #FBD10D;
      }
    }

    .svgtoggle {
      position: absolute;
      right: 5%;
      width: auto;
      display: block;
    }

    .whiteline {
      display: none;
    }

    svg {
      width: 122px;

    }

    label {
      width: 115.823px;
      display: none;
      height: 35.037px;
      font-size: 11px;
    }

    .text {
      display: none;

    }
  }

}
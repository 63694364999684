.egitsection {
    height: 100vh;

    .inneregitsection {
        height: 100vh;
        margin: auto;
        max-width: 1200px;
        display: flex;
        align-items: self-start;
        justify-content: space-between;
        padding: 4rem 0;
    }

    .left {
        height: 100%;
        overflow: scroll;
        display: flex;
        justify-content: center;
        flex-direction: column;

    }

    .right {
        height: 100%;
        overflow: scroll;
        width: calc(100% - 39rem);
        padding-left: 5rem;
    }

    .line {
        background: red;
        width: 10rem;
        height: 6px;
        border-radius: 30px;
        margin-bottom: 3rem;
    }

    .leftsidetextop {
        color: #fff;
        font-family: Montserrat;
        font-size: 33px;
        font-style: normal;
        font-weight: 600;
    }

    h1 {
        color: #fff;
        font-family: Montserrat;
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        /* z-index: 999999999; */
        /* position: absolute; */
        padding: 0 2rem;
        position: relative;
        z-index: 9999;
    }

    .blacktintint {
        background-color: #00000057;
        position: absolute;
        width: 100%;
        border-radius: 32px;
        height: 100%;
    }

    .rotacard {
        border-radius: 32px;
        background-image: url('../../../assets/pl.png');
        height: 400px;
        width: 400px;
        cursor: pointer;
        display: flex;
        align-items: end;
        /* background: red; */
        position: relative;
        /* transform: translateX(-50%); */
        transform: perspective(1200px) rotateX(40deg) translateZ(0px);
        background-size: cover;
        box-shadow: rgba(4, 153, 255, 0.06) 0px 0.602187px 0.48175px 0px, rgba(4, 153, 255, 0.227) 0px 2.28853px 1.83083px 0px, rgb(255 4 4) 0px 10px 8px 0px;
        transition: all 0.5s ease-out allow-discrete;

        &:hover {
            transition: all 0.5s ease-out allow-discrete;
            transform: scale(1.05);
        }

    }
}
.EducationDash {
    background: white;
    margin-top: 140px;

    .EducationSectionOne {
        max-width: 1200px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5rem 0rem;
        position: relative;
        margin: auto;
        border-radius: 30px;
        background: url('../../images/changeScetion.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: calc(100vh - 158px);
        display: flex;
        align-items: center;
        justify-content: center;


    }

    video {
        width: 100%;
        height: 100% !important;
        position: absolute;
        object-fit: cover;
        z-index: 0;
        left: 0;
        bottom: 0;
        border-radius: 30px;
        top: 0
    }

    .blacklint {
        height: 100%;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 30px;
        z-index: 1;
        background: #000000a8;
    }

    .title-eud {
        font-family: Montserrat;
        font-size: 45px;
        font-weight: 700;
        line-height: 55px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #FFFFFF;
        z-index: 2;
        position: relative;
    }

    .edu-cards {

        display: flex;
        align-items: center;
        gap: 2rem;
        z-index: 2;
        position: absolute;
        bottom: 39px;


        // margin: auto;

    }

    .line {
        height: 93px;
        width: 1px;

        background: #FBD10D;

    }

    .innertexsection {
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 9rem;
    }

    .list-edu {
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #FFFFFF;
        z-index: 2;
        position: relative;

    }

    .edu-label {
        width: 210px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 700;
        line-height: 25px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #000000;
        background: #FBD10D;
        border-radius: 10px;
        cursor: pointer;
        // margin-bottom: 8rem;
        z-index: 2;
        transition: all .25s ease-in-out;
        -moz-transition: all .25s ease-in-out;
        -webkit-transition: all .25s ease-in-out;
        position: relative;

        &:hover {
            transition: all .25s ease-in-out;
            -moz-transition: all .25s ease-in-out;
            -webkit-transition: all .25s ease-in-out;
            transform: scale(1.03);
        }

    }

    .edu-price {
        font-family: Montserrat;
        font-size: 40px;
        font-weight: 700;
        line-height: 55px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #FFFFFF;
        margin-top: 2rem;
        position: relative;
        margin-bottom: 2.5rem;
        z-index: 2;
    }

    .edu-des {
        font-family: Montserrat;
        font-size: 30px;
        font-weight: 700;
        line-height: 40px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #FFFFFF;
        position: relative;
        z-index: 2;
        margin-top: 10px;

        span {
            color: #FBD10D;

        }
    }
}

.Eduction-sec-two {

    .custom-accordion {
        width: 100%;
        max-width: 100%;
        margin: 0 auto;

        border-radius: 5px;
        overflow: hidden;
        margin-top: 2rem;
    }

    .custom-accordion-item {
        border-bottom: 1px solid #ddd;
    }

    .custom-accordion-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        color: #000;
        cursor: pointer;
        font-family: Montserrat;
        font-size: 17px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        transition: all .25s ease-in-out;
        -moz-transition: all .25s ease-in-out;
        -webkit-transition: all .25s ease-in-out;
   
    }

    .accordion-header:hover .custom-accordion-icon {
        transform: rotate(90deg);
    }
    .custom-accordion-header:hover {
        transition: all .25s ease-in-out;
        -moz-transition: all .25s ease-in-out;
        -webkit-transition: all .25s ease-in-out;
        //   background-color: #e6e6e6;
        transform: scaleX(1.02);
    }

    .custom-accordion-header.custom-active {
        // background-color: #d1e7fd;
    }

    .custom-accordion-icon {
        font-size: 20px;
    }

    .custom-accordion-content {
        max-height: 0;
        overflow: hidden;
        padding: 0 15px;
        background-color: #fff;
        transition: max-height 0.3s ease, padding 0.3s ease;
    }

    .custom-accordion-content.custom-show {
        max-height: 200px;
        padding: 15px;
        padding-top: 0;
    }

    .Eduction-inner {
        max-width: 1200px;
        width: 100%;
        display: flex;
        align-items: start;
        gap: 6rem;
        padding: 8rem 0;
        margin: auto;
    }

    .topflex-edu {
        display: flex;
        align-items: start;
        width: 100%;
        justify-content: space-between;
    }

    .leftside {
        width: 448px;
        height: 634px;
        display: flex;
        align-items: end;
        padding: 1rem 2rem;
        border-radius: 25px;
        background-image: url('../../images/shorupna123.png');
        background-size: cover;
        background-repeat: no-repeat;

    }

    .bottom-img {
        background: #000000D9;
        padding: 1rem;
        border-radius: 25px;

    }

    .Name-edu {
        font-family: Montserrat;
        font-size: 40px;
        font-weight: 700;
        line-height: 55px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #FBD10D;

    }

    .section-des-edu {
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #000000;
        margin-top: 10px;
    }

    .headerText {
        font-family: Montserrat;
        font-size: 30px;
        font-weight: 700;
        line-height: 48px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #000000;

    }

    .booklabel {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 700;
        line-height: 19.5px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #000000;
        width: 317.18px;
        height: 47.11px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background: #FBD10D;
        margin-top: 10px;
        cursor: pointer;
        transition: all .25s ease-in-out;
        -moz-transition: all .25s ease-in-out;
        -webkit-transition: all .25s ease-in-out;

        &:hover {
            transition: all .25s ease-in-out;
            -moz-transition: all .25s ease-in-out;
            -webkit-transition: all .25s ease-in-out;
            transform: scale(1.03);
        }
    }

    .svgimgsection {
        display: flex;
        align-items: start;
        gap: 1rem;

        svg {
            cursor: pointer;
            transition: all .25s ease-in-out;
            -moz-transition: all .25s ease-in-out;
            -webkit-transition: all .25s ease-in-out;

            &:hover {
                transition: all .25s ease-in-out;
                -moz-transition: all .25s ease-in-out;
                -webkit-transition: all .25s ease-in-out;
                transform: scale(1.03);
            }
        }
    }

    .edu-img-text {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 600;
        line-height: 35px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #FFFFFF8A;

    }

    .eud-img-name {
        font-family: Montserrat;
        font-size: 22px;
        font-weight: 600;
        line-height: 25px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: white;
    }
}

@media screen and (min-width:'900px') and (max-width:'1500px') {

    .EducationDash .EducationSectionOne {

        height: auto;
    }

}

@media screen and (min-width:'300px') and (max-width:'900px') {

    .Eduction-sec-two {

        //display: none;
    }

    .EducationDash .edu-cards {
        position: static;
    }

    .Eduction-sec-two .leftside {
        width: 100%;
        height: 434px;
        padding: 1rem 5%;
    }

    .Eduction-sec-two .eud-img-name {
        font-family: Montserrat;
        font-size: 19px;
        line-height: 20px;
    }

    .Eduction-sec-two .Eduction-inner {
        max-width: 1200px;
        width: 100%;
        display: block;
        padding: 0 5%;
    }

    .Eduction-sec-two {
        background: black;
        padding: 3rem 0;
    }

    .Eduction-sec-two .Name-edu {
        font-family: Montserrat;
        font-size: 34px;
        font-weight: 700;
        line-height: 44px;
    }

    .Eduction-sec-two .section-des-edu {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #fff;
        margin-top: 10px;
    }

    .Eduction-sec-two .headerText {
        font-family: Montserrat;
        font-size: 31px;
        font-weight: 700;
        color: white;
    }

    .Eduction-sec-two .booklabel {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 700;
        line-height: 19.5px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #000000;
        width: 100%;
        height: 37.11px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .Eduction-sec-two .edu-img-text {
        font-family: Montserrat;
        font-size: 15px;
    }

    .EducationDash {
        background: white;
        margin-top: 0;
        width: 100%;

    }

    .EducationDash .edu-des {
        font-family: Montserrat;
        font-size: 17px;
        font-weight: 700;
        line-height: 29px;

    }

    .EducationDash .edu-price {
        font-family: Montserrat;
        font-size: 26px;
        margin-top: 1rem;
        margin-bottom: 1.5rem;
    }

    .EducationDash .innertexsection {
        padding-bottom: 0;
    }

    .EducationDash .EducationSectionOne {
        max-width: 1200px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 5%;
        position: relative;
        padding-top: 150px;
        margin: auto;
        border-radius: 0px;
        background: black;
        background-size: cover;
        height: auto;
        background-repeat: no-repeat;
        background-position: center;
    }

    .EducationDash .title-eud {
        font-family: Montserrat;
        font-size: 35px;
        font-weight: 700;
        line-height: 47px;
    }

    .EducationDash .edu-label {
        width: 190px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 700;
        line-height: 25px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #000000;
        background: #FBD10D;
        border-radius: 10px;
        cursor: pointer;
        margin-bottom: 3rem;
    }

    .EducationDash .line {
        height: 1px;
        width: 207px;
        background: #FBD10D;
        margin: auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .EducationDash .edu-cards {
        display: block;
        align-items: center;
        gap: 2rem;
        z-index: 2;
        width: 100%;
    }

}

.faqsection {

    background: black;

    .innerfaq {
        max-width: 1200px;
        margin: auto;
        padding: 5rem 0;
    }

    .fqatitle {
        font-family: Montserrat;
        font-size: 22px;
        font-weight: 700;
        line-height: 25px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        margin-bottom: 2rem;
        color: white;

    }

    .accordion {
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 1rem;

    }

    .accordion-item {}

    .accordion-header {
        padding: 15px;
        background: #202020;
        border-radius: 10px 10px 0px 0px;
        cursor: pointer;
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;

        // transition: background 0.3s ease;


    }

    .accordion-header svg {
        transition: transform 0.3s ease;
        /* Ensure transition is applied to the SVG */
    }

    .accordion-header:hover svg {
        transform: rotate(90deg);
    }

    .accordion-header.active {
        //background: #007bff;
        // color: #fff;
    }

    .accordion-content {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease;
        background: #202020;
        border-radius: 0px 0px 10px 10px;
    }

    .accordion-content.open {
        max-height: auto;
        padding: 15px;
        background: #202020;
        padding-top: 0;
        min-height: max-content;
        // border-top: 1px solid #ddd;
    }

    .accordion-content p {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: white;
    }

}

.guEdege {

    /* App.css */
    // body {
    //     font-family: Arial, sans-serif;
    //     margin: 0;
    //     padding: 0;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     height: 100vh;
    //     background-color: #f4f4f4;
    // }
    .textedugede {

        font-family: Montserrat;
        font-size: 22px;
        font-weight: 700;
        line-height: 25px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: black;
        margin-bottom: 2rem;
    }

    .innerguEdege {
        padding: 8rem 0;
        max-width: 1200px;
        margin: auto;
    }

    .custom-card-container {
        display: flex;
        justify-content: space-between;
    }

    .custom-card {
        position: relative;
        width: 23%;
        height: 350px;
        overflow: hidden;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        transition: transform 0.3s ease;
        display: flex;
        flex-direction: column;
        justify-content: end;
        padding: 1rem;
    }

    .custom-card:hover {
        transform: translateY(-10px);
        border: 4px solid #FBD10D;
    }

    .custom-card:hover .card-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        width: 98%;
        height: 98%;
        left: 0;
        bottom: 0;
        top: 0;
        margin: auto;
        right: 0;
        border-radius: 10px;
    }

    .custom-card:hover .card-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 98%;
        height: 98%;
        background: rgba(0, 0, 0, 0.6);
        display: flex;
        border-radius: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        opacity: 1;
        transition: opacity 0.3s ease;
        left: 0;
        bottom: 0;
        top: 0;
        margin: auto;
        right: 0;
    }

    .card-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        bottom: 0;
    }

    .card-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    .custom-card:hover .card-button {
        display: block;
        position: relative;
        z-index: 4;
    }

    .custom-card:hover .card-overlay {
        opacity: 1;
    }

    .card-title {
        color: #fff;
        font-family: Montserrat;
        font-size: 22px;
        font-weight: 600;
        line-height: 25px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

        text-align: center;
        opacity: 1;
        z-index: 5;
        position: relative;
        margin-bottom: 16px;
    }

    .card-button {
        width: 119.04px;
        height: 29.58px;
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: center;
        margin: auto;
        gap: 0px;
        border-radius: 5px;
        background: transparent;
        color: #FBD10D;
        border: 1px solid #FBD10D;
        cursor: pointer;
        margin-bottom: 10px;
        transition: background-color 0.3s ease;
        margin: auto;
        margin-top: 0;
        margin-bottom: 16px;
    }

    .card-button:hover {
        transform: scale(1.03);
    }

    .features-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

        font-size: 14px;
        color: #000;
    }

    .feature {
        text-align: center;
        max-width: 150px;
        margin-top: 6rem;
    }

    .divider {
        width: 1px;
        height: 60px;
        background-color: #f5c518;
        margin-top: 6rem;
    }

}


@media screen and (min-width:'300px') and (max-width:'900px') {

    .faqsection .innerfaq {
        max-width: 1200px;
        margin: auto;
        padding: 3rem 5%;
    }

    .faqsection .fqatitle {
        font-family: Montserrat;
        font-size: 18px;
    }

    .faqsection .accordion-header {
        padding: 15px;
        background: #202020;
        border-radius: 10px 10px 0px 0px;
        cursor: pointer;
        font-family: Montserrat;
        font-size: 16px;
    }

    .faqsection .accordion-content p {
        font-family: Montserrat;
        font-size: 14px;
    }

    .guEdege .innerguEdege {
        padding: 4rem 5%;
        max-width: 1200px;
        margin: auto;
    }

    .guEdege .custom-card {
        position: relative;
        width: 100%;
    }

    .guEdege .custom-card-container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 1rem;
    }

    .guEdege .feature {
        text-align: center;
        max-width: 150px;
        margin-top: 3rem;
    }

    .guEdege .features-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 0.5rem
    }

    .guEdege .divider {
        width: 80%;
        height: 1px;
        background-color: #f5c518;
        margin-top: 1rem;
    }

    .guEdege .textedugede {
        font-family: Montserrat;
        font-size: 18px;
    }
}
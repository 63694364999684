.nine {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .textnine {
        color: #fff;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        margin-bottom: 4px;
    }

    .line {
        width: 1px;
        background: white;
        height: 20rem;
        margin-left: 60px;

    }

    .buttomdownmload {
        color: #fff;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        border-radius: 12px;
        height: 50px;
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 278px;
        background: #f5222d;
        cursor: pointer;

        &:hover {
            transform: scale(1.05);
        }
    }

    .texttime {
        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px;
        text-align: center;
    }

    .nineinner {
        max-width: 1200px;
        margin: auto;
    }

    .middleline {
        height: 3px;
        border-radius: 30px;
        background: red;
        width: 100px;
        margin-top: 6px;
        margin: auto;
        text-align: center;
        margin-top: 5px;
    }

    .boxtop {
        margin-bottom: 1.2rem;
        background: linear-gradient(304deg, #f5222d -23%, #640a0a 55%, #f5222d 106%);
        border-radius: 25px;
        box-shadow: rgba(255, 255, 255, 0.42) 0.421531px 0.48175px 1.66435px -0.916667px, rgba(255, 255, 255, 0.404) 1.60197px 1.83083px 6.32514px -1.83333px, rgba(255, 255, 255, 0.345) 7px 8px 27.6384px -2.75px;
        border: 1px solid #fff;
        display: flex;
        align-items: center;
        width: 774px;
        padding: 3rem 2rem;
        margin-top: 3rem;
    }

    .boxcard {
        align-items: center;
        display: flex;
        width: 350px;

        opacity: 1;

        svg {
            height: 32px;
        }
    }

    .textninesub {
        color: #fff;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        width: 228px;
        line-height: 25px;
    }
}
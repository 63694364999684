.sectionTwo {
    background: linear-gradient(rgb(0, 0, 0) 4%, #d60303ba 50%, rgb(0, 0, 0) 96%);
    height: 100vh;
    align-items: center;
    display: flex;
    margin: auto;
    justify-content: center;
    text-align: center;

    .innersectiontwo {
        max-width: 1200px;

    }

    .text {
        display: flex;
        margin-top: 13px;
        align-items: self-start;
    }

    .topheader {
        font-family: Montserrat;
        font-size: 34px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        color: #fff;
        margin-bottom: 40px;

    }

    .cardectiontwo {
        display: flex;
        align-items: self-start;
        justify-content: space-between;
    }

    .textPara {
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        color: #fff;
        text-align: left;
    }

    .header {
        color: #fff;

        font-family: Montserrat;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        color: #fff;
        margin-bottom: 14px;
        text-align: left;

    }

    .cardsSecttion {
        span {

            margin-right: 10px;
            background: white;
            border-radius: 12px;
            /* padding: 0.8rem; */
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
            color: red;
            width: 52px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        width: 32%;
        border: 1px solid white;
        background: linear-gradient(179deg, var(red red)
            /* {"name":"Navy Blue Blue"} */
            0%, #d60303ba 66%, var(#d60303ba, #d60303ba)
            /* {"name":"Blue Blue"} */
            100%);
        opacity: 1;
        border-radius: 20px;
        box-shadow: rgba(94, 94, 94, 0.72) 0px 0.60219px 0.60219px -1.25px,
        rgba(94, 94, 94, 0.635) 0px 2.28853px 2.28853px -2.5px,
        rgba(94, 94, 94, 0.25) 0px 10px 10px -3.75px;
        background: linear-gradient(179deg, red
            /* {"name":"Navy Blue Blue"} */
            0%, #d60303ba 66%, #d60303ba
            /* {"name":"Blue Blue"} */
            100%);
        padding: 1.5rem 1rem;
        cursor: pointer;

        &:hover {
            background: linear-gradient(179deg, red
                /* {"name":"Navy Blue Blue"} */
                -21%, #d60303ba 0%, #d60303ba
                /* {"name":"Blue Blue"} */
                100%);
        }

        img {
            border-radius: 20px;
            width: 100%;
        }
    }
}
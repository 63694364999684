/* App.css */
.sectionproductPage {
    height: 100vh;
    overflow: hidden;

    .input {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 600;
        line-height: 28px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #00000066;
        width: 580px;
        height: 60px;
        border-radius: 15px;
        background: #FFFFFF;
        border: 1px solid #F5F5F5;
        box-shadow: 0px 4px 4px 0px #00000040;
        padding-left: 1rem;
        position: absolute;
        bottom: -29px;
        right: 0;
        left: 0;
        margin: auto;
        z-index: 2;

    }

    .titlelist {
        font-family: Montserrat;
        font-size: 22px;
        font-weight: 600;
        line-height: 28px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #000000;
        margin-top: 18px;

    }

    .takepick {
        font-family: Montserrat;
        font-size: 22px;
        font-weight: 500;
        line-height: 28px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #FFFFFF;

    }

    .ourProducts {
        font-family: Montserrat;
        font-size: 40px;
        font-weight: 700;
        line-height: 55px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #FFFFFF;

    }

    .top-section-page {
        background-color: #000;
        // max-width: 1200px;
        // margin: auto;
        position: relative;
        padding: 4rem 0;
        padding-top: 11rem;
        padding-bottom: 6rem;
    }

    #top-section h1 {
        margin: 0;
        font-size: 32px;
    }

    #top-section p {
        margin-top: 10px;
        font-size: 18px;
    }

    .main-content {
        display: flex;
        flex-direction: row;
        background-color: #f4f4f4;
        position: relative;
    }

    .max-page-section {
        max-width: 1200px;
        margin: auto;
        width: 100%;
        display: flex;
        flex-direction: row;
        z-index: 1;
    }

    .sidebar {
        min-width: 250px;
        background-color: #f4f4f4;
        // padding: 10px;
        // box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
        position: relative;
        top: 0;
        transition: position 0.3s ease;
        padding-top: 3rem;
        // height: 100vh;
    }

    .sidebar.sticky {
        //position: fixed;
        top: 0;
    }

    .itemsectiongloabl {
        position: absolute;
        right: 0;
        height: 100vh;
        background: white;
        width: 50%;
        /* z-index: 1; */
        top: 0;

    }

    .sidebar-item {
        display: block;
        margin: 10px 0;
        padding: 10px;
        text-align: left;
        background: none;
        border: none;
        cursor: pointer;
        font-size: 16px;
        transition: color 0.3s ease, transform 0.2s ease;
        width: 100%;
    }

    .sidebar-item.active {
        color: #000;
        font-weight: bold;
        border-left: 4px solid #FBD10D;
        background-color: #fbd10d4a;
    }

    .sidebar-item:hover {
        color: #000;
    }

    .titlelist {
        font-family: Montserrat;
        font-size: 22px;
        font-weight: 600;
        line-height: 28px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #000000;
        margin-bottom: 1.5rem;

    }

    .cardsectionproduct {
        background: white;
        // margin-top: 2rem;
        display: flex;
        flex-wrap: wrap;
        gap: 2%;
    }



    .content {
        padding-left: 60px;
        padding-right: 0px;
        overflow-y: scroll;
        padding-top: 3rem;
        background: white;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding-bottom: 2rem;
        height: calc(100vh - 355px);
    }

    .trucconent {
        width: 100% !important;
        padding-left: 295px;
    }

    .titleName {
        font-family: Montserrat;
        font-size: 17px;
        font-weight: 600;
        line-height: 20.72px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #000000;

    }

    .des {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #000000;
    }

    .product-section {
        width: 32%;
        height: 292px;
        top: 490px;
        left: 394px;
        gap: 2%;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: start;
        gap: 0px;
        border-radius: 15px;
        border: 1px solid #E7E7E7;
        cursor: pointer;
        box-shadow: 0px 2px 2px 0px #00000040;
        transition: all .25s ease-in-out;
        -moz-transition: all .25s ease-in-out;
        -webkit-transition: all .25s ease-in-out;

        &:hover {
            transform: scaleY(1.02);
            transition: all .25s ease-in-out;
            -moz-transition: all .25s ease-in-out;
            -webkit-transition: all .25s ease-in-out;

            svg {
                transition: all .25s ease-in-out;
                -moz-transition: all .25s ease-in-out;
                -webkit-transition: all .25s ease-in-out;
                transform: rotate(360deg);
            }
        }

    }

    .product-section:nth-child(even) {
        background-color: #f9f9f9;
    }

    h2 {
        margin-bottom: 20px;
        font-size: 24px;
    }

    p {
        font-size: 16px;
        color: #555;
    }

}


@media screen and (min-width:'0px') and (max-width:'900px') {
    .sectionproductPage .ourProducts {
        font-family: Montserrat;
        font-size: 26px;
    }

    .sectionproductPage {
        height: auto;
        overflow: visible;
    }

    .sectionproductPage .top-section-page {
        padding: 0 5%;
        padding: 165px 5%;
        padding-bottom: 85px;
    }

    .sectionproductPage {
        // padding: 0 5%;
    }

    .sectionproductPage .titlelist {
        margin-top: 1rem;
    }

    .sectionproductPage .product-section {
        width: 32%;
        height: 215px;
    }

    .sectionproductPage .takepick {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 500;
        line-height: 23px;
    }

    .sectionproductPage .max-page-section {
        max-width: 1200px;
        margin: auto;
        width: 100%;
        display: block;
        flex-direction: row;
    }

    .sectionproductPage .sidebar-item {
        display: block;
        padding: 0;
        margin: 0px 0;
        white-space: nowrap;

    }

    .sidebar-item.active {
        color: #000;
        font-weight: bold;
        border-left: 0px solid #FBD10D !important;
        border-bottom: 4px solid #FBD10D !important;
        background-color: transparent !important;
    }

    .sectionproductPage .product-section {
        width: 100%;
    }

    .sectionproductPage .trucconent {
        width: 100% !important;
        padding-left: 0;
    }

    .sectionproductPage .content {
        padding-left: 5%;
        padding-right: 5%;
        overflow-y: scroll;
        padding-top: 1rem;
        background: white;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        height: auto;
    }

    .sectionproductPage .cardsectionproduct {
        background: white;
        display: flex;
        flex-wrap: wrap;
        gap: 2%;
        gap: 1.3rem;
    }

    .sectionproductPage .sidebar {
        min-width: 250px;
        background-color: #f4f4f4;
        position: relative;
        top: 0;
        transition: position 0.3s ease;
        padding-top: 0rem;
        height: auto;
        display: flex;
        gap: 1.2rem;
        overflow: scroll;
        padding: 0 1.5rem;
        display: none;
        height: 41px;
    }



    .sectionproductPage .input {
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 600;
        line-height: 28px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: rgba(0, 0, 0, 0.4);
        width: 80%;
        height: 51px;
        border-radius: 15px;
        background: #FFFFFF;
        border: 1px solid #F5F5F5;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
        padding-left: 1rem;
        position: static;
        bottom: -29px;
        right: 0;
        left: 0;
        margin: auto;
        z-index: 1;
        display: flex;
        margin-top: 2rem;
    }

}
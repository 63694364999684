.mainthibg {
    // background: linear-gradient(0deg, #C0A00A 0%, #C0A00A 17%, #C0A00A 20%, #18191d 55%, #18191d 70%, #18191d 112%);
    height: 100vh;
    overflow: scroll;
    background: linear-gradient(0deg, #C0A00A -34%, #C0A00A -10%, #C0A00A -18%, #18191d 42%, #18191d 34%, #18191d 96%);
}

.landingSection {
    align-items: center;


    display: flex;
    flex: 0 0 auto;
    flex-flow: column;

    overflow: hidden;

    position: relative;
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 7rem;

    .sectioncard {
        width: 100%;
        display: flex;
        align-items: self-start;
        justify-content: space-between;
    }

    .cardtext {
        display: flex;
        align-items: center;

        span {
            min-width: 47px;
            height: 47px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 12px;
            font-family: Montserrat;
            font-size: 22px;
            font-weight: 700;
            line-height: 28px;
            text-align: left;
            color: #FFFFFF;
            background: #FBD10D;
            border-radius: 44rem;
        }
    }

    .cardtextHeader {
        font-family: Montserrat;
        font-family: Montserrat;
        font-size: 22px;
        font-weight: 700;
        line-height: 31px;
        color: #FFFFFF;
    }

    .para {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 600;
        line-height: 25px;
        text-align: left;
        color: #FFFFFF;
        margin-top: 1rem;
    }

    .top-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        width: 100%;
        padding-top: 158px;
        padding-bottom: 117px;
    }

    .cardSectionOne {
        width: 32%;
        background: #00000085;
        border-radius: 35px;
        padding: 1.5rem;
        cursor: pointer;


        &:hover {
            transform: scale(1.05);
        }

        img {
            margin-bottom: 1rem;
        }

        height: 472px;


        img {
            width: 100%;
        }
    }

    .innersectionone {
        max-width: 1200px;
        margin: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .subtext {
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 600;
        line-height: 35px;
        text-align: center;
        color: white;
    }

    .textlabel {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 700;
        line-height: 21.94px;
        text-align: center;
        color: white;
        margin-top: 8px;
    }

    .label {
        width: 286.54px;
        height: 55px;
        border-radius: 35px;
        background: #FBD10D;
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 700;
        line-height: 25px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;

        cursor: pointer;
        margin-top: 3.5rem;


        &:hover {
            position: relative;

            box-shadow: -6px 14px 12px 2px rgba(90, 90, 90, .12);
            transform: scale(1.03);
            background: black;
            color: white;
            border: 1px solid white;
        }
    }

    .hearder {
        font-family: Montserrat;
        font-size: 60px;
        font-weight: 700;
        line-height: 85px;
        text-align: center;
        color: white;
    }
}


// body {
//     background: rgb(0, 0, 0);
// }

@media screen and (min-width:'300px') and (max-width:'900px') {
    .landingSection .hearder {
        font-family: Montserrat;
        font-size: 39px;
        font-weight: 700;
        line-height: 54px;
        text-align: center;
        color: #fff;
        margin-bottom: 2rem;
    }
    .landingSection {
    
        padding-bottom: 1rem;
    }
    .landingSection .para {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        text-align: left;
        color: #fff;
        margin-top: 1rem;
    }

    .landingSection .cardtext span {
        font-family: Montserrat;
        font-size: 18px;
        line-height: 22px;
    }

    .landingSection .cardtext span {
        min-width: 36px;
        height: 33px;
    }

    .landingSection .cardSectionOne {
        width: 100%;
        background: rgba(0, 0, 0, .5215686274509804);
        border-radius: 20px;
        padding: 0.5rem;
        cursor: pointer;
        margin-bottom: 1.2rem;
        height: 432px;
    }

    .landingSection .sectioncard {
        width: 100%;
        display: block;

    }

    .landingSection .label {
        width: 245.54px;
        height: 46px;
        border-radius: 35px;
        background: #fbd10d;
        font-size: 16px;
        line-height: 25px;
    }

    .landingSection .textlabel {
        font-size: 11px;
        line-height: 21.94px;
    }

    .landingSection .top-content {

        padding-top: 72px;
        padding-bottom: 81px;
    }

    .landingSection .innersectionone {

        padding: 0 5%;
    }

    .landingSection .subtext {

        font-size: 20px;
        font-weight: 600;
        line-height: 38px;

    }

}
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


}
h1,h2,h3,h4,h5,h6, p,span, button,label div{
  font-family: 'Montserrat', sans-serif !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.coinImg
{
    width: 50px !important; 
    padding-bottom: 1rem;
    padding-top: 3px;
}
.sectionImage
{
    margin: 38px;
    margin: 38px;
    text-align: center;
    padding: 17px;
    border-radius: 19px;
    height: 9rem;
    width: 9rem;
    -webkit-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    -moz-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    -ms-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    -o-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.imageslider_section ._1JwaBMUCMsc8QmGrT7DX45
{
    display:none
}
.c_image{
    width:100px;
    padding-right: 12px;
}
.Image_Tittle
{
    display:flex;
    align-items: center;
}
.Main_Title
{
    padding-right: 10%;
}
.Coin_Types_selcetion
{
display: flex;
align-items: flex-end;
flex-wrap: wrap;
height: 50%;

border-bottom: 1px solid grey;
}
.coin-type
{
flex:1;
}
.coin-Email{
    flex:1;
}
h5{
    font-size:1rem;
}
.coin-Country
{
    flex:1;
    padding-left: 5rem;
}
.WebSite_detail
{
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    height: 50%;
    padding-top: 12px;

}
.bibox-top
{
background-color: #2196F3;
    width: 100%;
    height: 10px;
    position: relative;
    border-radius: 6px;
    top: -16px;
}
.Allcoin_section
{
border-right: 1px solid #dcd5d5;
}
.website
{
    flex:1;
}
.Fee{
    flex:1;
}
.Chat
{
    flex:1;
}
.compare-button{
    flex:1;
}
.imageslider_section{
    background-color:#b3d4fc14;
}
.coinPriceSelection
{
    padding-top: 40px;
}
.Coininformation 
{
    padding-bottom: 42px;
}

.compare-btn{
    font-size: 16px;
    font-weight: bold;
    padding-left: 20px;
    border-radius: 14px;
    padding-right: 24px;
}
.hover_section:hover
{
   color: grey;
   cursor: pointer;
   
}

.Email-suport
{
color:blue;
cursor: pointer;
text-decoration: underline;
}
.row
{
    margin-left: 15px;
    margin-right: 15px;
}
.SubPriceSelection
{
    flex:2;
}
.coin-image
{
  padding-right:10px  
}
.font-coin
{   
font-weight: bold;
}
.search {
    width: 100%;
    position: relative;
    display: flex;
  }
  
  .searchTerm {
    width: 100%;
    border: 3px solid #00B4CC;
    border-right: none;
    padding: 5px;
    height: 20px;
    border-radius: 5px 0 0 5px;
    outline: none;
    color: #9DBFAF;
  }
  
  .searchTerm:focus{
    color: #00B4CC;
  }
  
  .searchButton {
    width: 40px;
    height: 36px;
    border: 1px solid #00B4CC;
    background: #00B4CC;
    text-align: center;
    color: #fff;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    font-size: 20px;
  }
  
  /*Resize the wrap to see the search bar change!*/
  .wrap{
    width: 30%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .navbars a
  {
      color:#ffff !important;
  }
  .up{
    border: 2px solid #03A9F4;  
  }
  .bi
  {
    border: 2px solid #03A9F4;
  }
  .pol
  {
    border: 2px solid #03A9F4;  
  }
  .kuc
  {
    border: 2px solid #03A9F4;  
  }
  .bn
  {
    border: 2px solid #03A9F4; 
  }
  .bt
  {
    border: 2px solid #03A9F4; 
  }
  .bit
  {
    border: 2px solid #03A9F4;  
  }
  .cos{
    border: 2px solid #03A9F4;    
  }
  .kar{
    border: 2px solid #03A9F4;    
  }
  .containe 
  {
margin-top: 120px;
  }
  a:hover
  {
      text-decoration: none;
      color:black;
  }
  .imageslider_section a
  {
    text-decoration: none;
    color:black;
  }
  .imageslider_section a:hover
  {
    text-decoration: none;
    color:black;
  }
  .WebSite_detail a
  {
      color:black
  }
  .WebSite_detail a:hover
  {
      color:black
  }
@media only screen and (min-width:0px) and (max-width:500px)
{
.Coin_Types_selcetion
{
height: 100%;
}
.coin-Country
{
    padding-left: 0;
}
.coin-Email {
    padding-right: 12px;
}
.Main_Title {
     padding-right: 15px;
    text-align: justify;
}
.WebSite_detail
{
   padding-top: 2rem;

}
}

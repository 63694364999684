.sectonfive {

    padding: 7rem 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .bottomsection {
        display: flex;
        align-items: start;
        gap: 4rem;
    }

    .textteacher {
        font-family: Montserrat;
        font-size: 33px;
        font-weight: 700;
        line-height: 55px;
        text-align: left;
        color: #FFFFFF;
    }

    .hedaertitle {
        font-family: Montserrat;
        font-size: 52px;
        font-weight: 600;
        line-height: 65px;
        text-align: left;
        color: #FBD10D;

    }

    .linecontent {
        font-family: Montserrat;
        font-size: 17px;
        font-weight: 700;
        line-height: 33px;
        text-align: left;
        color: #FFFFFF;
        padding: 1.5rem;
        width: 39rem;
        border: 35px;
        border: 1px solid white;
        border-radius: 35px;
        margin-top: 4rem;
        background: #00000085;

    }

    .littletext {
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 600;
        line-height: 45px;
        text-align: left;
        color: #FFFFFF8A;

    }

    .labelmeet {
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 700;
        line-height: 24.38px;
        text-align: center;
        color: #000;
        width: 100%;
        margin-top: 1.3rem;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 35px;
        background: #FBD10D;
        cursor: pointer;

        &:hover {
            position: relative;

            box-shadow: -6px 14px 12px 2px rgba(90, 90, 90, .12);
            transform: scale(1.03);
            background: black;
            color: white;
            border: 1px solid #fff;
        }
    }

    .balay {
        background: #000000D9;
        padding: 1rem;
        border-radius:
            25px;
        position: absolute;
        width: 100%;
        bottom: 0;
    }

    .TOPname {
        display: flex;
        align-items: self-start;
        justify-content: space-between;
    }

    .imgsection {
        display: flex;
        align-items: center;

        gap: 1rem;

        svg {
            cursor: pointer;

            &:hover {
                transform: scale(1.03);
            }
        }
    }

    .innerfive {
        max-width: 1200px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
    }

    .Name {
        font-family: Montserrat;
        font-size: 26px;
        font-weight: 600;
        line-height: 32px;
        text-align: left;
        color: #FFFFFF;

    }

    .cardSection {
        width: 346px;
        min-width: 346px;
        height: 539px;
        border-radius: 25px;
        position: relative;
        background: #FFFFFF;

        img {
            height: 100%;
            width: 100%;
        }
    }



}

@media screen and (min-width:'300px') and (max-width:'900px') {

    .sectonfive {
        padding: 0rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 4rem;
    }

    .sectonfive .linecontent {
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 700;
        line-height: 26px;
        text-align: left;
        color: #FFFFFF;
        padding: 1rem;
        width: 100%;
        border: 35px;
        border: 1px solid white;
        border-radius: 35px;
        margin-top: 2rem;
        background: #00000085;
    }

    .sectonfive .bottomsection {
        display: block;
        align-items: start;
        grid-gap: 4rem;
        gap: 4rem;
        row-gap: 4rem;
        column-gap: 4rem;
    }

    .sectonfive .hedaertitle {
        font-family: Montserrat;
        font-size: 41px;
        font-weight: 600;
        line-height: 48px;
        text-align: left;
        color: #FBD10D;
    }

    .sectonfive .textteacher {
        font-family: Montserrat;
        font-size: 26px;
        font-weight: 700;
        line-height: 55px;
        text-align: left;
        color: #FFFFFF;
        margin-top: 2rem;
    }

    .sectonfive .cardSection {
        width: 100%;
        min-width: 100%;
        height: 539px;
        border-radius: 25px;
        position: relative;
        background: #FFFFFF;
    }

    .sectonfive .innerfive {
        max-width: 1200px;
        display: block;
        padding: 0 5%;
        width: 100%;
    }

}
.sectionfour {
    padding: 7rem 0;
    display: flex;
    align-items: center;
    justify-content: center;


    .label {
        background: #FBD10D;
        font-family: Montserrat;
        font-size: 22px;
        font-weight: 700;
        line-height: 25px;
        text-align: center;
        color: #000000;
        width: 321.32px;
        height: 48.03px;
        border-radius: 44rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        margin-bottom: 5px;
        cursor: pointer;

        &:hover {
            position: relative;

            box-shadow: -6px 14px 12px 2px rgba(90, 90, 90, .12);
            transform: scale(1.03);
            background: black;
            color: white;
            border: 1px solid white;
        }
    }

    .innerfour {
        max-width: 1200px;
        margin: auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .linetext {
        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 50px;

        text-align: center;
        margin-bottom: 4rem;
    }

    .border-div-class {
        border: 1px solid #fff;
        border-radius: 35px;
        padding: 2rem;
        width: 40rem;
        background: #00000085;

    }

    .textheader {
        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
    }

    ul {
        padding-left: 15px;
    }

    li {
        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 31px;
        text-align: left;
        margin-top: 1rem;
    }
}

@media screen and (min-width:'300px') and (max-width:'900px') {
    .sectionfour .linetext {
        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 49px;
        text-align: center;
        margin-bottom: 3rem;
        font-size: 29px;
    }

    .sectionfour .label {
        background: #FBD10D;
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 700;
        line-height: 25px;
        text-align: center;
        color: #000000;
        width: 235.32px;
    }

    .sectionfour li {
        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 23px;
        text-align: left;
        margin-top: 1rem;
    }

    .sectionfour .border-div-class {
        border: 1px solid #fff;
        border-radius: 35px;
        padding: 1rem;
        width: 100%;
        background: #00000085;
    }

    .sectionfour {
        padding: 6rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6rem 5%;
    }

}
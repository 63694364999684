.TopHeaderSection {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background: #FBD10D;
    position: fixed;
    width: 100%;
    z-index: 9;
    top:0px;


    .headerText {
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 700;
        line-height: 25px;
        text-align: center;
        color: #000000;
        cursor: pointer;
        transition: all .25s ease-in-out;
        -moz-transition: all .25s ease-in-out;
        -webkit-transition: all .25s ease-in-out;

        &:hover {
            transition: all .25s ease-in-out;
            -moz-transition: all .25s ease-in-out;
            -webkit-transition: all .25s ease-in-out;
            transform: scale(1.03);
        }
    }
}


@media screen and (min-width:'300px') and (max-width:'900px') {

    .TopHeaderSection {

        display: none !important;
    }

}
.Alumni {
    padding-bottom: 3rem;
    padding-top: 7.5rem;

    .cardalume {
        width: 100%;
        padding: 3rem;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

    }

    .clasimga {
        position: absolute;
        right: 2rem;
        bottom: 0;
        height: 99%;
    }

    .des {
        width: 416px;

        font-family: Montserrat;
        font-size: 18px;
        font-weight: 600;
        line-height: 30px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        margin-top: 2rem;

    }

    .sectionSectionalume {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        // gap: 4rem;
        width: 100%;
        column-gap: 2rem;
        margin-top: 2rem;
        //  flex-direction: column;
    }

    .inner {
        max-width: 1200px;
        width: 100%;
        margin: auto;
    }

    .titleTect {
        font-family: Montserrat;
        font-size: 25px;
        font-weight: 500;
        min-width: 250px;
        line-height: 25px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #000000;

    }

    .label {
        width: max-content;
        padding: 1rem 2rem;
        height: 50px;
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        display: flex;
        align-items: center;
        justify-content: center;
        justify-content: center;
        border-radius: 10px;
        margin-top: 2rem;
        color: white;
        cursor: pointer;
        transition: all .25s ease-in-out;
        -moz-transition: all .25s ease-in-out;
        -webkit-transition: all .25s ease-in-out;

        &:hover {
            transform: scale(1.02);
            transition: all .25s ease-in-out;
            -moz-transition: all .25s ease-in-out;
        }
    }
}


@media screen and (min-width:'300px') and (max-width:'900px') {

    .Alumni .sectionSectionalume {
        flex-direction: column;
        display: flex;
        align-items: center;
        width: 100%;
        column-gap: 2rem;
        margin-top: 2rem;
        text-align: center;
        justify-content: center;
    }

    .Alumni .titleTect {
        font-family: Montserrat;
        font-size: 22px;
        font-weight: 500;
        min-width: 250px;
        line-height: 25px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #000000;
        min-width: auto;
        margin-bottom: 1rem;
    }

    .Alumni .des {
        width: auto;
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 600;
        line-height: 26px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        margin-top: 1.5rem;
    }
.Alumni{
    padding: 0 5%;
    margin-top: 9rem;
    img {
        width: 80%;
    }
}
    .Alumni .cardalume {
        width: 100%;
        padding: 2rem;
    }

    .Alumni .glablimg {
        display: none;
    }

    .Alumni .label {
        width: max-content;
        padding: 0.8rem 1.5rem;
        height: 50px;
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 600;
        line-height: 25px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        display: flex;
        align-items: center;
        justify-content: center;
        justify-content: center;
        border-radius: 10px;
        margin-top: 1.5rem;
        color: white;
        cursor: pointer;
        transition: all 0.25s ease-in-out;
        -moz-transition: all 0.25s ease-in-out;
        -webkit-transition: all 0.25s ease-in-out;
    }

}
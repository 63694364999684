.ten {
    padding: 5rem 0;

    .number {
        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 51px;
        font-style: normal;
        font-weight: 600;
        line-height: 51px;
    }

    .inner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        max-width: 1200px;
        margin: auto;
    }

    .headertext {
        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 42px;
        text-align: center;
    }

    .line {
        height: 3px;
        border-radius: 30px;
        background: red;
        width: 300px;
        margin-top: 6px;
        text-align: center;
    }

    .boxsection {
        display: flex;
        align-items: start;
        gap: 1.2rem;
        margin-top: 6rem;
    }

    .boc {
        width: 257px;
        height: 174px;
        border: 2px solid red;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 32px;
    }

    .text {
        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        margin-top: 12px;
    }
}